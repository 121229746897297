import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { getMessagesData } from "../utils/helper";
import {
  IItems,
  IItemsMessages,
  ISecondLevelmessages,
} from "../utils/interfaces";
import Popup from "./Popup";
import { IHistoryItem } from "../../../models/models";
interface ISideBarProps {
  selectedService: IHistoryItem | undefined;
  setIsPopUpOpen: any;
  setSecondLevelmessages: any;
  setTitle: any;
  setStatusDisplayName: any;
}

export default function SideBar({
  selectedService,
  setIsPopUpOpen,
  setSecondLevelmessages,
  setTitle,
  setStatusDisplayName,
}: ISideBarProps) {
  if (!selectedService) {
    return null;
  }

  const handleMessageClick = (message: IItemsMessages, status: string) => {
    setIsPopUpOpen(true);
    getMessagesData(setSecondLevelmessages, message.id);
    setTitle(message.title);
    setStatusDisplayName(status);
  };
  const messages = selectedService.messages as IItemsMessages[];
  return (
    <>
      <div className="text-container">
        {selectedService.messages!.length < 1 ? (
          <>
            <h3>There are no Incidents</h3>
            <p>Everything seems fine with {selectedService.service}</p>
          </>
        ) : (
          <>
            {messages.map((message: IItemsMessages) => {
              return (
                <div
                  onClick={() =>
                    handleMessageClick(
                      message,
                      selectedService.status
                    )
                  }
                  className="message-container"
                  key={message.id}
                >
                  <h4>{message.title}</h4>
                  <p>
                    <span>Estimated start time:</span> {message.startTime}
                  </p>
                  <p>
                    <span>LastUpdate:</span> {message.lastUpdatedTime}
                  </p>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
