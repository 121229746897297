import { Callout } from "@fluentui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  Dialog,
  DialogFooter,
  IconButton,
} from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { getItemsForCSVExport } from "../../../api/message.service";
import { BasicButton, CancelButton } from "../../../commonComponents/Buttons";
import { useTeamsContext } from "../../../context/teamsContext";
import { convertHTMLToText } from "../../../helpers/helpers";
import { IMessage, ISelectedItem } from "../../../models/models";
import { Iitems } from "../utils/interfaces";

interface downloadButtonProps {
  filteredItems: IMessage[];
  csvFilename: string;
}
interface CSVData {
  "Message Title": string;
  Services: string;
  "Last Updated": string;
  "Message ID": string;
  Category: string;
  Severity: string;
  Message: string;
}
export default function DownloadButtonPlusAPI({
  filteredItems,
  csvFilename,
}: downloadButtonProps) {
  const {getTenantId} = useTeamsContext()
  const [data, setData] = useState<any[] | CSVData[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(true);
  const [parent] = useAutoAnimate()
  const handleCloseDownloadModal = () => {
    setIsDownloadModalOpen(true);
    setData(undefined);
  };
  const handleDownload = () => {
    setIsDownloadModalOpen(false);
    setIsTooltipOpen(true);
  };
  const handleAPICall = async () => {
    setIsLoading(true);
    const fetchedData = await Promise.all(
      filteredItems.map(async (item) => {
        try {
          const params  = {
            messageId: item.id,
            TenantId: (getTenantId && getTenantId()) || ""
          }
          const message = await getItemsForCSVExport(params);
          return {
            "Message Title": item.messageTitle,
            Services: Array.isArray(item.service) ? item.service.join(",") : item.service,
            "Last Updated": item.lastUpdatedTime,
            "Message ID": item.id,
            Category: item.category,
            Severity: item.severity,
            Message: convertHTMLToText(message).replaceAll('"', "''"),                
          };
        } catch (error) {
          // ignore errors if a person wasn't found
          return null;
        }
      })
    );
    const sanitizedData = [...fetchedData].filter((item) => item !== null);
    setData(sanitizedData);
    setIsLoading(false);
  };

  return (
    <div ref={parent}>
      {isLoading ? (
        <div className="m-1" id={"downloadButton"}>
          <Spinner style={{ display: "inline" }} size={SpinnerSize.medium} />
        </div>
      ) : (
        <IconButton
          iconProps={{ iconName: "Download" }}
          onClick={handleAPICall}
          className="ml-2 font-semibold text-white transition duration-100 ease-in-out rounded bg-sky-700 hover:bg-sky-900"
          title="Download result"
          aria-label="Download result"
          id={"downloadButton"}
        />
      )}
      {isDownloadModalOpen && data ?
      <FileReadyModal
        data={data}
        handleCloseDownloadModal={handleCloseDownloadModal}
        handleDownload={handleDownload}
        csvFilename={csvFilename}
      />
      : null }
      <PopUpLight
        isPopUpLightHidden={isTooltipOpen}
        setIsPopUpLightHidden={setIsTooltipOpen}
        setData={setData}
      />
    </div>
  );
}

interface INoticeProps {
  handleDownload: () => void;
  handleCloseDownloadModal: () => void;
  data: CSVData[];
  csvFilename: string;
}
export const FileReadyModal = ({
  handleDownload,
  handleCloseDownloadModal,
  data,
  csvFilename,
}: INoticeProps) => {
  return (
    <div className="bg-black/[0.7] fixed top-0 left-0 w-full h-full d-flex items-center justify-center" style={{zIndex: 9000}}>      
      <div className="max-w-screen-sm mx-2 bg-white rounded-lg shadow">
        <div className="relative w-full px-2 py-3 rounded-t bg-sky-700 ">
          <h1 className="m-0 text-lg md:text-2xl text-sky-50">CSV file ready for Download!</h1>
          <button className="absolute top-0 right-0 px-2 text-3xl transition duration-100 ease-in-out border-none bg-none text-sky-50 hover:text-red-600" onClick={handleCloseDownloadModal}>
            <i className="">×</i>
          </button>
        </div>
        <div>
          <p className="px-4 pt-4 mb-4 text-gray-700">Do you want to download the CSV file with {data.length} items?</p>
        </div>
        <div className="flex justify-end px-4 pb-4 ">
          <CSVLink data={data} filename={csvFilename}>
            <BasicButton handleClick={handleDownload}>Download</BasicButton>
          </CSVLink>
          <CancelButton handleClick={handleCloseDownloadModal} classes="bg-gray-600">Ignore</CancelButton>
        </div>
      </div>
    </div>
  );
}
interface IPopUpLightProps {
  isPopUpLightHidden: boolean;
  setIsPopUpLightHidden: any;
  setData: any;
}
export function PopUpLight({
  isPopUpLightHidden,
  setIsPopUpLightHidden,
  setData,
}: IPopUpLightProps) {
  const closePopUpLight = () => {
    setIsPopUpLightHidden(true);
    setData([]);
  };
  return isPopUpLightHidden ? null : (
    <Callout
      role="alertdialog"
      gapSpace={0}
      setInitialFocus
      target="#downloadButton"
      onDismiss={closePopUpLight}
      style={{ padding: "10px 20px" }}
    >
      <h1 style={{ fontWeight: "lighter" }}>
        <i
          className="ms-Icon ms-Icon--DownloadDocument"
          aria-hidden="true"
          style={{ marginRight: "10px" }}
        />
        File downloaded
      </h1>

      <p style={{ fontWeight: "lighter" }}>
        You can find your file in your local Download folder
      </p>
    </Callout>
  );
}
