import "./Table.css";
import { useState, useEffect } from "react";
import { MessageModal } from "./components/MessageModal";
import {RenderColumns} from "./components/renderColumns";
import {
  Dropdown,
  IconButton,
  SearchBox,
  DetailsList,
  DetailsListLayoutMode,
  Toggle,
} from "@fluentui/react";
import { IMicrosoftServices } from "./utils/interfaces";
import Header from "../../commonComponents/Header";
import DownloadButtonPlusAPI from "./components/DownloadButtonPlusAPI";
import { CloudHubDivider } from "../../commonComponents/Divider";
import { useWindowSize } from "../../hooks/useWindowSize";
import { IMessage } from "../../models/models";
import { useQuery, useQueryClient } from "react-query";
import { fetchMessages, setMessageStatus } from "../../api/message.service";
import { IMessageRequest, ISetMessageStatusRequest } from "../../api/models";
import { useTeamsContext } from "../../context/teamsContext";
import { generateArrayOfYears, markItemAsArchived, markItemAsFavorite, markItemAsRead } from "../../helpers/helpers";
import { desktopColumns, mobileColumns } from "./config/table.config";
import { useMSServices } from "../../hooks/useMSServices";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ShareModalComponent } from "./components/ShareModalComponent";
import { LoadingComponent } from "../../commonComponents/LoadingComponent";
import { app,} from "@microsoft/teams-js";
import { ConsentHeader } from "../../commonComponents/ConsentHeader";

export const MessageCenterIndex = () => {
  // const {handleInitToken} = useScopedAuth()
  const [parent] = useAutoAnimate()
  const {getBasicUserData, loggedIn} = useTeamsContext()
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const {services} = useMSServices()
  const paramsBase = getBasicUserData && getBasicUserData()
  const params: IMessageRequest = {
    ...paramsBase,
    Year: selectedYear,
    MessageType: "MessageCenter"
  }
  const {isLoading, data } = useQuery<IMessage[]>(["Messages", params], () => fetchMessages(params))
  const qc = useQueryClient()
 
  const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IMessage>();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [selectedShareItem, setSelectedShareItem] = useState<IMessage>();
  const [visibleItems, setVisibleItems] = useState<IMessage[]>([])
  const [filteredItems, setFilteredItems] = useState<IMessage[]>([]);
  const [serviceFilterValue, setServiceFilterValue] = useState<string | null>(
    null
  );
  const [keyWordFilterValue, setKeyWordFilterValue] = useState<string>("");
  const [showOnlyFavorites, setShowOnlyFavorites] = useState<boolean>(false);
  const [showOnlyArchives, setShowOnlyArchives] = useState<boolean>(false);
  const [windowWidth] = useWindowSize();



  const handlesServiceFilterChange = (text: IMicrosoftServices) => {
    setServiceFilterValue(text.key);
  };
  const handlesYearFilterChange = (yearOption: IMicrosoftServices) => {
    setSelectedYear(parseInt(yearOption.text));
    handleInvalidateQuery()
  };
 

  const handleKeyWordFilterChange = (newValue: string) => {
    setKeyWordFilterValue(/^\s/.test(newValue) ? "" : newValue);
  };
  
  const handleReset = () => {
    setKeyWordFilterValue("");
    setServiceFilterValue(null);
    setFilteredItems(data || []);
    setShowOnlyArchives(false);
    handleInvalidateQuery()
  };
  const handleClearKeyWordFilter = () => {
    setKeyWordFilterValue("");    
  };
  const handleFavoriteToggle = () => {
    setShowOnlyFavorites(prevState => !prevState);    
  };
  const handleInvalidateQuery = () => {
    qc.invalidateQueries(["Messages", params])
  }
  const handleSelectItem = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>, item: IMessage) => {
    setSelectedItem(item);
    setIsMessageModalOpen(true)
    setFilteredItems(markItemAsRead([...filteredItems], item));
  }
  const handleSetFavorite = async (e: React.MouseEvent<HTMLElement, MouseEvent>, item: IMessage) => {
    const params: ISetMessageStatusRequest = {
      TenantId: paramsBase?.TenantId ||"",
      Actions: [{ MessageId: item.id, Action: 2, Value: !item.isFavorite }]
    }
    const response = await setMessageStatus(params);
    if(response) {
      setFilteredItems(markItemAsFavorite([...filteredItems], item))   
      filterFunction()  
    }
  }
  const handleSetArchiveItem = async (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, item: IMessage) => {
    const params: ISetMessageStatusRequest = {
      TenantId: paramsBase?.TenantId || "",
      Actions: [{ MessageId: item.id, Action: 1, Value: !item.isArchived }]
    }
    const response = await setMessageStatus(params);
    
    if(response){
      setFilteredItems(markItemAsArchived([...filteredItems], item))     
      filterFunction()
      
    }
  }
  const handleSelectShareItem = ( item: IMessage) => {
    setSelectedShareItem(item);
    setIsShareModalOpen(true)
  }

  const handleCloseMessageModal = () => {
    setIsMessageModalOpen(false)
    setSelectedItem(undefined)
  }
  const handleCloseShareModal = () => {
    setIsShareModalOpen(false)
    setSelectedItem(undefined)
  }
  const filterFunction = () => {
    if(!data) return []
    let tempArray = [...filteredItems];
  
    if (keyWordFilterValue) {
      tempArray = tempArray.filter((item) => {
        return (
          filterHelper(item.id) ||
          filterHelper(item.lastUpdatedTime) ||
          filterHelper(item.messageTitle) ||
          filterHelper(item.category) ||
          filterHelper(item.severity) ||
          item.service.some((res: string) => filterHelper(res))
        );
      });
    }
    
    if (serviceFilterValue) {
      tempArray = tempArray.filter((item) => {
        return item.service.some((res: string) => res.toLowerCase().includes(serviceFilterValue.toLowerCase()));
      });
    }
  
    if (showOnlyFavorites) {
      tempArray = tempArray.filter((item) => {
        return item.isFavorite === true;
      });
    }
    if(showOnlyArchives) {
      tempArray = tempArray.filter((item) => {
        return item.isArchived === true;
      });
    }
    if(!showOnlyArchives) {
      tempArray = tempArray.filter((item) => {
        return item.isArchived === false;
      });
    }
    setVisibleItems(tempArray)
  
}
  const filterHelper = (text: string) => {
    return text.toLowerCase().includes(keyWordFilterValue.toLowerCase());
  };
 
  useEffect(() => {
    if (data) {
      setFilteredItems(data);
      filterFunction()
    }
  }, [data]);
  useEffect(() => {
    filterFunction()
  }, [filteredItems]);
  useEffect(() => {
    filterFunction()
  }, [serviceFilterValue, keyWordFilterValue, showOnlyFavorites, showOnlyArchives]);

  // useEffect(() => {
  // if(loggedIn?.approved) 
  // handleInitToken()
  // }, [loggedIn]);
    
  if(isLoading) return null
  if (!isLoading) app.notifySuccess()
  return (
    <div className="relative h-full" ref={parent}>
      {!isLoading ? <ConsentHeader /> : null }
      <Header title="Message Center" icon="MessageFill" />
      <div className="flex items-center justify-between w-full px-4 py-2 m-auto">
        <ul className="flex p-0 m-0">
          <li
          className={`mr-2 border-b-2 cursor-pointer p-1 hover:border-sky-700 ${!showOnlyArchives ? "border-sky-700 font-bold" : 'border-transparent'}`}
            onClick={() => setShowOnlyArchives(false)}
          >
            Inbox
          </li>
          <li
            onClick={() => setShowOnlyArchives(true)}
            className={`border-b-2 p-1 cursor-pointer hover:border-sky-700 ${showOnlyArchives ? "border-sky-700 font-bold" : 'border-transparent'}`}
          >
            Archive
          </li>
        </ul>
        <Toggle
          className="toggle"
          inlineLabel
          onText="Favorites"
          offText="Favorites"
          onChange={handleFavoriteToggle}
        />
      </div>
      <hr></hr>
      <div className="flex flex-col flex-wrap justify-between w-full px-4 m-auto md:flex-row" >
        <div className="flex">
          <Dropdown
            className="mr-2"
            options={generateArrayOfYears()}
            onChange={(e, text: any) => handlesYearFilterChange(text)}
            label="Year"
            placeholder={selectedYear.toString()}
          />
          {services ? <Dropdown
            options={services}
            className="w-full md:w-52"
            onChange={(e, text: any) => handlesServiceFilterChange(text)}
            selectedKey={serviceFilterValue}
            label="Service"
            placeholder="None"
          /> : null}
        </div>
        <div className="flex items-end">
          <div className="w-full md:ml-2">
            <label className="py-1 pl-0 m-0 text-sm text-semibold">Keyword</label>
            <SearchBox
              placeholder="Search... "
              onChange={(e, text) => handleKeyWordFilterChange(text ?? "")}
              value={keyWordFilterValue}
              className="w-full border-sky-700 hover:border-sky-900 focus:outline-none focus:border-sky-900 hover:bg-sky-50 focus:bg-sky-50 md:w-52"
              onClear={() => handleClearKeyWordFilter()}
            />
          </div>
          <div className="flex items-end">
            {/* <BasicButton handleClick={handleSubmit}>Search</BasicButton> */}
            <IconButton
              iconProps={{ iconName: "ClearFilter" }}
              onClick={handleReset}
              className="ml-2 font-semibold text-white rounded bg-sky-700 hover:bg-sky-900"
              title="Reset all filters"
              ariaLabel="Reset all filters"
            />
            <DownloadButtonPlusAPI
              filteredItems={filteredItems}
              csvFilename="MessageCenter.csv"
            />
          </div>
        </div>
      </div>
      <CloudHubDivider count={visibleItems.length} wide/>
      {visibleItems.length > 0 ? (
        <div className="list-container">
          <DetailsList
            items={visibleItems}
            columns={windowWidth > 900 ? desktopColumns : mobileColumns}
            getKey={(item) => item.key}
            selectionMode={0}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            onRenderItemColumn={(
              item: IMessage,
              index: number | undefined,
              column: any
            ) =>
              RenderColumns(
                item,
                column,
                handleSelectItem,
                handleSelectShareItem,
                handleSetArchiveItem,
                handleSetFavorite,
              )
            }
          />
        </div>
      ) : (
        <p className="my-12 text-xl text-center text-sky-700">No results found</p>
        )}
      
      {isMessageModalOpen && selectedItem ?  
      <MessageModal            
        selectedItem={selectedItem}
        handleClose={handleCloseMessageModal}
      /> : null}
      {selectedShareItem && isShareModalOpen ? 
        <ShareModalComponent
        selectedItem={selectedShareItem}
        handleClose={handleCloseShareModal}
      /> : null }
      
    </div>

  );
  
}



