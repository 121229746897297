import {
  Dropdown,
  Icon,
  IconButton,
  ITooltipProps,
  Spinner,
  SpinnerSize,
  DirectionalHint,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";

import PeoplePicker, { Person } from "./PeoplePicker";
import { handleOpenLink } from "../../../helpers/helpers";
import { IMessage } from "../../../models/models";
import { useChannelsData, useShareModalData } from "../../../hooks/useShareModalData";
import { SendChatMessage, SendChannelMessage, InitChat, IChatMessage, IChatMember, SendEmail } from "../../../api/message.service";
import { useTeamsContext } from "../../../context/teamsContext";
import { BasicButton, CancelButton } from "../../../commonComponents/Buttons";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { SendMessageModel } from "../../../api/models";
import { useScopedAuth } from "../../../hooks/useScopedAuth";

interface IPopUpProps {
  selectedItem: IMessage;
  handleClose: () => void;
}

export const ShareModalComponent = ({ selectedItem, handleClose }: IPopUpProps) => {
  const { getTenantId, context } = useTeamsContext()
  const { handleInitToken } = useScopedAuth()
  const { teams, messageBody } = useShareModalData({ messageId: selectedItem.id })
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const { channels, setTeamId } = useChannelsData()
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [selectedPeople, setSelectedPeople] = useState<Person[]>([]);
  const [selectedPeopleEmail, setSelectedPeopleEmail] = useState<Person[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  // const [subject, setSubject] = useState<string>("");
  const tenantId = getTenantId && getTenantId() || "";
  const [parent] = useAutoAnimate()

  const subject = selectedItem?.messageTitle;
  const service = selectedItem?.service;
  const startTime = selectedItem?.startTime;
  const endTime = selectedItem?.endTime;
  const category = selectedItem?.category;
  const severity = selectedItem?.severity;
  const messageId = selectedItem?.id;
  const messageHeader = `<p>Service: <strong>${service}</strong></p><p>Message ID: <strong>${messageId}</strong></p><p>Start Time: <strong>${startTime}</strong></p><p>End Time: <strong>${endTime}</strong></p><p>Severity: <strong>${severity}</strong></p><p>Category: <strong>${category}</strong></p><br>`;
  useEffect(() => {
    if (selectedTeam && selectedTeam !== "") {
      setTeamId(selectedTeam)
    }
  }, [selectedTeam])

  useEffect(() => {
    handleInitToken()
  }, [])

  const prepareChatData = (): IChatMessage => {

    // Error when I select myself and then add it too - Take out if available
    const selectedChatMembers = selectedPeople.filter(person => person.id !== context?.user?.id);

    const chatData: IChatMessage = {
      chatType: selectedChatMembers.length > 1 ? "group" : "oneOnOne",

      members: [{
        "@odata.type": "#microsoft.graph.aadUserConversationMember",
        "roles": ["owner"],
        "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${context?.user?.id}')`
      }]
    }
    
    if (selectedChatMembers.length > 1) chatData.topic = `Cloud Hub-Message Center-${subject}`

    selectedChatMembers.forEach(person => {
      const userData: IChatMember = {
        "@odata.type": "#microsoft.graph.aadUserConversationMember",
        "roles": ["owner"],
        "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${person.id}')`
      }
      chatData.members.push(userData)
    })

    return chatData
  }
  const prepareEmailData = () => {
    const emailData = {
      message: {
        subject: `Cloud Hub-Message Center-${subject}}`,
        body: {
          contentType: "html",
          content: `<p><strong>Cloud Hub-Message Center-${subject}</strong></p>${messageHeader}${messageBody?.messageText}`
        },
        toRecipients: selectedPeopleEmail.map(person => {
          return {
            emailAddress: {
              address: person.email
            }
          }
        })
      },
      saveToSentItems: "true"
    }
    return emailData
  }

  const handleSubmitChannel = async () => {
    setErrorMessage(undefined)
    setSuccessMessage(undefined)
    if (!messageBody) return setErrorMessage("There is no message to send!");
    let errorCount = 0;
    const chatData = prepareChatData()
    const emailData = prepareEmailData()
    const cantSendEmail = chatData.members.length < 2 && selectedChannel === "" && emailData.message.toRecipients.length < 1
    if (cantSendEmail) return setErrorMessage("Please choose a Team/Channel or People to proceed!");

    if (selectedChannel && selectedChannel !== "") {
     
      const sendMessageParams: SendMessageModel = {
        TenantId: tenantId,
        TeamId: selectedTeam,
        ChannelId: selectedChannel,
        Message: `${messageHeader}${messageBody.messageText}`,
        Subject: `Cloud Hub-Message Center-${subject}`
      }
      const response = await SendChannelMessage(sendMessageParams)
      if (!response) {
        setErrorMessage("Something went wrong, please try again later!")
        errorCount++;
      }
    }
    if (chatData.members.length > 1) {

      const chatId = await InitChat(chatData)
      if (!chatId) return setErrorMessage("Chat can't be created or not found!")

      const sendMessageParams: SendMessageModel = {
        TenantId: tenantId,
        TeamId: selectedTeam,
        ChannelId: selectedChannel,
        Message: `<p><strong>Cloud Hub-Message Center-${subject}</strong></p><br>${messageHeader}${messageBody.messageText}`,
        ChatId: chatId
      }
      SendChatMessage(sendMessageParams).then(response => { }).catch(error => {
        
        setErrorMessage("Something went wrong, please try again later!")
        errorCount++;
      })


    }
    if (emailData.message.toRecipients.length > 0) {
      const response = await SendEmail(emailData)
      if (!response) {
        setErrorMessage("Something went wrong, please try again later!")
        errorCount++;
      }
    }
    if (errorCount === 0) {
      setSuccessMessage("Messages has been sent successfully!")
      setTimeout(() => {
        setSuccessMessage(undefined)
        handleClose()
      }, 2000)
    }
  };

  const handleTeamsDropdownChange = (text: string) => {
    setSelectedTeam(text);
  };

  const handleSelectPeople = (recipients: Person[]) => {
    setSelectedPeople(recipients)
  }

  useEffect(() => {
    // Do nothing only Workaround for Array is up to date
    // Otherwise always one action back
    console.log("selectedPeople")
  }, [selectedPeople, selectedPeopleEmail])

  const handleSelectPeopleEmail = (recipients: Person[]) => {
    setSelectedPeopleEmail(recipients)

  };


  return (
    <div className="bg-black/[0.7] fixed top-0 left-0 z-5 w-full h-full d-flex items-center justify-center">
      <div className="w-full max-w-[400px] bg-white rounded-lg shadow ">
        <div className="relative w-full px-3 py-3 rounded-t bg-sky-700 ">
          <h1 className="m-0 md:text-xl text-sky-50">Sharing: {selectedItem?.id}</h1>
          <button className="absolute top-0 right-0 px-2 text-3xl transition duration-100 ease-in-out border-none bg-none text-sky-50 hover:text-red-600" onClick={handleClose}>
            <i className="">×</i>
          </button>
        </div>
        <div className="flex flex-col px-4 py-3 pb-2" ref={parent}>
          <Dropdown
            options={teams!}
            onChange={(e, text: any) => handleTeamsDropdownChange(text.key)}
            onRenderLabel={() => renderTeamsLabel(teams)}
            className="mb-3"
            placeholder="None"
          />
          {channels && channels.length > 0 ?
            <Dropdown
              options={channels}
              onChange={(e, text: any) => setSelectedChannel(text.key)}
              onRenderLabel={() => renderChannelLabel(channels, selectedTeam)}
              className="mb-3"
              placeholder="None"

              disabled={selectedTeam ? false : true}
            /> : null}
          <div className="mb-3">
            <PeoplePicker type="chat"
              handleSelectPeople={handleSelectPeople}
            />
          </div>
          <div className="mb-1">
            <PeoplePicker type="email"
              handleSelectPeople={handleSelectPeopleEmail}
            />
          </div>
          {errorMessage ? <p className="text-sm font-semibold text-center text-red-500"> {errorMessage}</p> : null}
          {successMessage ? <p className="font-semibold text-center text-green-500 "> {successMessage}</p> : null}

          <div className="flex flex-wrap justify-end mt-3">
            <BasicButton handleClick={handleSubmitChannel} classes="flex items-center w-full md:w-auto mb-2 md:mr-3"><Icon iconName="Share" className="mr-2" />Share Message</BasicButton>
            <CancelButton handleClick={handleClose} classes="mx-0 flex items-center w-full md:w-auto mb-2"><Icon iconName="Cancel" className="mr-2" /> Cancel</CancelButton>
          </div>
        </div>
      </div>
    </div>
  );
}

const renderTeamsLabel = (teams: any): JSX.Element => {
  return (
    <>
      <Stack horizontal verticalAlign="center">
        <i className="mr-2 text-indigo-900 ms-Icon ms-Icon--TeamsLogo" />
        <span className="font-semibold">Select the Team you want to post</span>
        {teams ? null : <Spinner size={SpinnerSize.small} />}
      </Stack>
    </>
  );
};
const renderChannelLabel = (channel: any, teams: any): JSX.Element => {
  const iconPicker = (channel: any, selectedTeam: any) => {
    if (selectedTeam && !channel) {
      return <Spinner size={SpinnerSize.small} />;
    } else if (selectedTeam && channel.length === 0) {
      return (
        <>
          <TooltipHost
            tooltipProps={tooltipProps}
            id="ErrorToolTip"
            directionalHint={DirectionalHint.bottomCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <IconButton
              iconProps={{ iconName: "Error" }}
              aria-describedby="ErrorToolTip"
            />
          </TooltipHost>
        </>
      );
    } else {
      return null;
    }
  };


  return (
    <>
      <Stack horizontal verticalAlign="center">
        <i className="mr-2 text-indigo-900 ms-Icon ms-Icon--TeamsLogo" />
        <span className="font-semibold">Select the Channel you want to post</span>
        {iconPicker(channel, teams)}
      </Stack>
    </>
  );
};

const tooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <>
      <p>
        An error has occurred! The selected Team doesn't have any channels with
        the required email-permission.
        <br /> Please follow{" "}
        <a
          rel="noopener noreferrer"
          href="https://teams.handsontek.net/2019/09/12/how-to-send-an-email-to-a-channel/"
          target="_blank"
          onClick={handleOpenLink}
        >
          theses steps.
        </a>
      </p>
    </>
  ),
};

