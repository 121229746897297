import { useEffect, useLayoutEffect, useMemo, useState, } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "react-query";
import { MessageModal } from "./components/MessageModal";
import Header from "../../commonComponents/Header";
import { IHistoryItem, IIncidentItem } from "../../models/models";
import { useWindowSize } from "../../hooks/useWindowSize";
import { HistoryListItem } from "../../commonComponents/HistoryListItem";
import { fetchIncidents, getIncidents, getServices } from "../../api/incidents.service";
import { RenderMessages } from "../../commonComponents/MessageListItem";
import { app } from "@microsoft/teams-js";
import { ConsentHeader } from "../../commonComponents/ConsentHeader";
import { useTeamsContext } from "../../context/teamsContext";
// import { useScopedAuth } from "../../hooks/useScopedAuth";
import { BasicButton } from "../../commonComponents/Buttons";
import { IMessageRequest } from "../../api/models";

export const IncidentCenterIndex = ( ) => {
  const {loggedIn, needConsent} = useTeamsContext()
 /*  const params: IMessageRequest = {
    Year: new Date().getFullYear(),
    MessageType: "IncidentCenter",
  } */
  const {getBasicUserData} = useTeamsContext();
  // if(getBasicUserData === undefined) return <Spinner />
  const paramsBase = getBasicUserData && getBasicUserData()
  
  const params:IMessageRequest = {
   ...paramsBase,
    Year: new Date().getFullYear(),
    MessageType: "MessageCenter",
  }
  
  // const {isFetching, data} = useQuery<IHistoryItem[]>(['getIncidents', params], () => fetchIncidents(params)); 
  // const {handleInitToken} = useScopedAuth()
  const {isLoading, data} = useQuery<IHistoryItem[]>(['getIncidents', params ], () => fetchIncidents(params), {
    retryDelay: 1000,
    retry: 3,
    refetchOnWindowFocus: false,
  }); 
  const [selectedService, setSelectedService] = useState<IHistoryItem>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [parent] = useAutoAnimate()
  const [activeMessage, setActiveItem] = useState<IIncidentItem>();
  const [showConsentLink, setShowConsentLink] = useState<boolean>(false)
  const handleCopyLink = () => {
      if(!loggedIn) return
      navigator.clipboard.writeText(loggedIn?.approveLink)
      .then(() => alert("Link copied to clipboard"))
      .catch((err) => {
          setShowConsentLink(true)
      });
  }

  const handleOpenModal = (message: IIncidentItem ) => {    
    setActiveItem(message);
    setIsModalOpen(true);
  }

  const handleToggleService = (item: IHistoryItem) => {
    
    if (selectedService?.id === item.id) return setSelectedService(undefined);   
    // getIncident(item.service)
    setSelectedService(item);
  }
  // const getIncident = async (service: string) => {
  //   const incident = await getIncidents(service)
  //   setSelectedService(incident)
  // }
  const handleCloseModal = () => {
    // setActiveItem(undefined);
    setIsModalOpen(false);
  };
  
  // useLayoutEffect(() => {
  //   if(loggedIn?.approved) 
  //   handleInitToken()
  // }, [loggedIn]);
  const sortedIncidents = useMemo(() => data ? sortIncidents(data) : [], [data])

  if(isLoading) return null
  if (!isLoading) app.notifySuccess()
  return (
    <div ref={parent} className="relative h-full">
      {!isLoading ? <ConsentHeader /> : null }
      <Header title="Incident Center" icon="HealthSolid" /> 
      {data && data.length > 0 ?
          <div className="w-full max-w-screen-lg px-4 mx-auto">        
            <h2 className="my-8 text-3xl text-center text-sky-700">Service Status</h2>
            <ul className="m-auto">
              {sortedIncidents.map((item, index) => {
                return (
                  <li ref={parent} className="mb-2 " key={item.id} tabIndex={index}>
                    <HistoryListItem item={item} isOpen={selectedService?.id === item.id} handleClick={handleToggleService} >
                    {selectedService?.id === item.id && selectedService.messages
                        ? <RenderMessages messages={selectedService.messages} handleClick={handleOpenModal} />
                        : null} 
                      
                    </HistoryListItem>
                  </li>
                );
              })}
            </ul>
          </div>
         : null }
       {/*   {data && data.length === 0 ? <div className="flex-col items-center justify-center w-11/12 px-2 mx-auto mt-16 md:items-start d-flex" ref={parent}>
          <h2 className="mb-2 text-2xl font-semibold text-sky-700">Forbidden Function</h2>
          <p className="text-lg font-semibold text-sky-900">Your organisation has not allowed you to acces this content. Please contact your administrator to gain acces.</p>
         </div> : null } */}
          
         {/* { needConsent ?  <div className="flex-col items-center justify-center w-11/12 px-2 mx-auto mt-16 md:items-start d-flex" ref={parent}>
          <h2 className="mb-2 text-2xl font-semibold text-sky-700">Enhanced Security and Improved Features for Cloud Hub</h2>
          <p className="text-lg font-semibold text-sky-900">Admin Approval Required for User Consent to Access Advanced Features</p>
          <p className="mt-8 mb-4 text-center text-md md:text-left text-sky-900">This update brings added security measures and exciting new features to Cloud Hub. With admin approval required for user consent to access advanced features, you can rest assured that your data is secure. The extended message center functions allow for seamless sharing in channels, chats with more people, and even the ability to send emails on behalf of the user.</p>
          <p className="mb-8 text-center text-md md:text-left text-sky-900">
These improvements are designed to enhance the user experience and provide greater functionality for teams working in Cloud Hub. With this update, users can take advantage of new tools and features, while admins can maintain control over access to sensitive data. Overall, this update provides a more secure and streamlined experience for Cloud Hub users.</p>
          {showConsentLink ? <p className="py-1 font-semibold">{loggedIn?.approveLink}</p> : <BasicButton handleClick={handleCopyLink} >Show Link</BasicButton> }
        </div> : null } */}
 
        
       
     {activeMessage && isModalOpen ? <MessageModal activeMessage={activeMessage} status={selectedService?.service!}
        handleClose={handleCloseModal}
      /> : null} 
      
    </div>
  )
}






export const sortIncidents = (data: IHistoryItem[]) : IHistoryItem[] => {
  return data.sort(
    (a: IHistoryItem, b: IHistoryItem) => {
      if (a.status.toLowerCase() === "serviceinterruption") {
        return -1;
      } else if (b.status.toLowerCase() === "serviceinterruption") {
        return 1;
      } else if (a.status.toLowerCase() === "postincidentreviewpublished") {
        return -1;
      } else if (b.status.toLowerCase() === "postincidentreviewpublished") {
        return 1;
      } else if (a.status.toLowerCase() === "servicedegradation") {
        return -1;
      } else if (b.status.toLowerCase() === "servicedegradation") {
        return 1;
      } else if (a.status.toLowerCase() === "extendedrecovery") {
        return -1;
      } else if (b.status.toLowerCase() === "extendedrecovery") {
        return 1;
      } else if (a.status.toLowerCase() === "servicerestored") {
        return -1;
      } else if (b.status.toLowerCase() === "servicerestored") {
        return 1;
      } else if (a.status.toLowerCase() === "serviceoperational") {
        return -1;
      } else if (b.status.toLowerCase() === "serviceoperational") {
        return 1;
      } else {
        return 0;
      }      
    }
  );
}