import { useEffect } from "react";

export default function ConsentPage() {
  const urlChecker = (url: string) => {
    console.log(url);
    let urlResult;
    if (url.includes("denied")) {
      urlResult = false;
    } else if (url.includes("admin_consent=True")) {
      urlResult = true;
    } else {
      urlResult = false;
    }
    if (urlResult) {
      return urlResult;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (urlChecker(window.location.href)) {
      const tid = window.location.href.split("tenant=")[1].replace("#", "");
      registerNewUser(tid);
    }
  }, []);

  return (
    <div>
      
      <h1 className="py-4 text-3xl font-semibold text-center bg-sky-700 text-sky-50">Cloud Hub Consent Page</h1>

      {urlChecker(window.location.href) ? (
        <div className="flex flex-col items-center">
          <h2 className="my-4 text-3xl text-center text-sky-700">Thanks for using Cloud Hub</h2>
          <p className="px-4 mb-4 text-center text-sky-900">
            Thanks for approving the required permissions. The app will be ready
            to use within a few minutes.
            <br />
            You can close this window now.
            <br />
            <b>Enjoy using Cloud Hub.</b>
          </p>
          <div className="flex flex-col items-center w-[350px]">
            <img src="/approved.svg" className="w-full" alt="cancel"/>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <h2 className="my-4 text-3xl text-center text-sky-700">An error occurred</h2>
          <p className="px-4 mb-4 text-center text-sky-900">
            You propbaly declined the necessary Permission to use the app.
            Unfortunately, we are not able to proceed without them. Please
            consider accepting the permission if you want to use Cloud Hub.
            <br /> If you did accept the Permissions and still see this page
            please contact our Support Team under support@atwork.at.
            <br />
            You can close this window now.
          </p>
          <div className="flex flex-col items-center w-[350px]">
            <img src="/cancel.svg" className="w-full" alt="cancel"/>
          </div>
        </div>
      )}
    </div>
  );
}

async function registerNewUser(tid: string) {
  const data = { tenantid: tid };
  //@ts-ignore
  await fetch(process.env.REACT_APP_AZURE_FUNCTION_URL, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
