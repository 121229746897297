import { IItems } from "./interfaces";
import * as microsoftTeams from "@microsoft/teams-js";

const mockServiceStatus: any[] = [
  {
    id: "yammer",
    services: "Yammer Enterprise",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "SwayEnterprise",
    services: "Sway",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Stream",
    services: "Microsoft Stream",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "StaffHub",
    services: "Microsoft StaffHub",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "SharePoint",
    services: "SharePoint Online",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "RMS",
    services: "Azure Information Protection",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "PowerBIcom",
    services: "Power BI",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "PowerAppsM365",
    services: "Power Apps in Microsoft 365",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "PowerApps",
    services: "Power Apps",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Planner",
    services: "Planner",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "PAM",
    services: "Privileged Access",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "OSDPPlatform",
    services: "Microsoft 365 suite",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "OrgLiveID",
    services: "Identity Service",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "OneDriveForBusiness",
    services: "OneDrive for Business",
    messages: [
      {
        lastUpdatedTime: "26 Aug 2021",
        startTime: "26 Aug 2021",
        title:
          "Users' OneDrive for Business storage limits are lower than expected",
        id: "OD280960",
      },
    ],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "officeonline",
    services: "Office for the web",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "O365Client",
    services: "Microsoft 365 Apps",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "MobileDeviceManagement",
    services: "Mobile Device Management for Office 365",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "microsoftteams",
    services: "Microsoft Teams",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "MicrosoftFlowM365",
    services: "Microsoft Power Automate in Microsoft 365",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "MicrosoftFlow",
    services: "Microsoft Power Automate",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "MDATP",
    services: "Microsoft Defender for Endpoint",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Lync",
    services: "Skype for Business",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Intune",
    services: "Microsoft Intune",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Forms",
    services: "Microsoft Forms",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Exchange",
    services: "Exchange Online",
    messages: [
      {
        lastUpdatedTime: "25 Aug 2021",
        startTime: "18 Aug 2021",
        title:
          "Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the Security and Compliance Center",
        id: "EX280618",
      },
      {
        lastUpdatedTime: "09 Sept 2021",
        startTime: "09 Sept 2021",
        title: "Users can't add shared mailboxes via Outlook mobile apps",
        id: "EX284109",
      },
    ],
    status: "ServiceDegradation",
    statusDisplayName: "Service degradation",
  },
  {
    id: "DynamicsCRM",
    services: "Dynamics 365 Apps",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "cloudappsecurity",
    services: "Cloud App Security",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Bookings",
    services: "Microsoft Bookings",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "MicrosoftDefenderforIdentity",
    services: "Microsoft Defender for Identity",
    messages: [],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
  {
    id: "Microsoft365Defender",
    services: "Microsoft 365 Defender",
    messages: [
      {
        lastUpdatedTime: "13 Sept 2021",
        startTime: "13 Sept 2021",
        title:
          "Users are unable to export a devices timeline within M365Defender",
        id: "DZ284692",
      },
    ],
    status: "ServiceOperational",
    statusDisplayName: "Normal service",
  },
];

const mockMessageData: any = [
  {
    id: "EX280618",
    publishTime: "25 Aug 2021, 01:48",
    messageText:
      "Title: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the Security and Compliance Center\n\nUser Impact: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the SCC\n\nMore info: Impact is specific to admins with the specific role entitled eDiscovery Managers. Additionally, impact is specific to Microsoft Teams records.\n\nCurrent status: We've identified there was an outdated certification which prevented Microsoft Teams call records from being posted to eDiscovery. We've corrected the issue for Tuesday August 24, 2021 and onwards. We are now in the process of backfilling the data from Wednesday August 18, 2021 to Monday August 23, 2021.\n\nScope of impact: Any admin attempting to see Microsoft Teams call records within the Security and Compliance Center within the dates of August 18, 2021 to August 23, 2021 are affected.\n\nStart time: Wednesday, August 18, 2021, at 8:30 PM UTC\n\nRoot cause: An outdated certification prevented Microsoft Teams call records from being posted to eDiscovery, resulting in impact.\n\nNext update by: Tuesday, August 31, 2021, at 11:00 PM UTC",
  },
  {
    id: "EX280618",
    publishTime: "25 Aug 2021, 01:29",
    messageText:
      "Title: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the Security and Compliance Center\n\nUser Impact: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the SCC\n\nMore info: Impact is specific to admins with the specific role entitled eDiscovery Managers. Additionally, impact is specific to Microsoft Teams records.\n\nCurrent status: We're investigating a potential issue and checking for impact to your organization. We'll provide an update within 30 minutes.",
  },
  {
    id: "EX280618",
    publishTime: "01 Sept 2021, 01:48",
    messageText:
      "Title: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the Security and Compliance Center\n\nUser Impact: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the SCC\n\nMore info: Impact is specific to admins with the specific role entitled eDiscovery Managers. Additionally, impact is specific to Microsoft Teams records.\n\nCurrent status: We’re in the process of preparing the missing data as well as preparing the pipeline before we initiate the backfill process. Everything is progressing as expected and we have an anticipated mitigation date of Friday, September 10, 2021.\n\nScope of impact: We’re in the process of preparing the missing data as well as preparing the pipeline before we initiate the backfill process. Everything is progressing as expected and we have an anticipated mitigation date of Friday, September 10, 2021.\n\nStart time: Wednesday, August 18, 2021, at 8:30 PM UTC\n\nRoot cause: An outdated authentication component prevented Microsoft Teams call records from being posted to eDiscovery, resulting in impact.\n\nNext update by: Wednesday, September 8, 2021, at 1:30 AM UTC",
  },
  {
    id: "EX280618",
    publishTime: "08 Sept 2021, 01:51",
    messageText:
      "Title: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the Security and Compliance Center\n\nUser Impact: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the SCC.\n\nMore info: Impact is specific to admins with the specific role entitled eDiscovery Managers. Additionally, impact is specific to Microsoft Teams records.\n\nCurrent status: We've confirmed that the data and pipeline have been fully staged and the backfill of the missing data is now in progress. We anticipate the backfill to be completed by Saturday, September 11, 2021.\n\nScope of impact: Any admin attempting to see Microsoft Teams call records within the Security and Compliance Center within the dates of August 18, 2021 to August 23, 2021 are affected.\n\nStart time: Wednesday, August 18, 2021, at 8:30 PM UTC\n\nEstimated time to resolve: Based on current backfill progress, we expect the missing data to be available within the next four days.\n\nRoot cause: An outdated authentication component prevented Microsoft Teams call records from being posted to eDiscovery, resulting in impact.\n\nNext update by: Saturday, September 11, 2021, at 12:00 AM UTC",
  },
  {
    id: "EX280618",
    publishTime: "11 Sept 2021, 00:11",
    messageText:
      "Title: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the Security and Compliance Center\n\nUser Impact: Admins are unable to see call records between August 18, 2021 and August 23, 2021 in the SCC.\n\nMore info: Impact is specific to admins with the specific role entitled eDiscovery Managers. Additionally, impact is specific to Microsoft Teams records.\n\nCurrent status: The backfill of the missing data is progressing more slowly than previously anticipated. We now expect the backfill to be completed by Thursday, September 16, 2021.\n\nScope of impact: Any admin attempting to see Microsoft Teams call records within the Security and Compliance Center within the dates of August 18, 2021 to August 23, 2021 are affected.\n\nStart time: Wednesday, August 18, 2021, at 8:30 PM UTC\n\nEstimated time to resolve: Based on current backfill progress, we expect the missing data to be available by Thursday, September 16, 2021.\n\nRoot cause: An outdated authentication component prevented Microsoft Teams call records from being posted to eDiscovery, resulting in impact.\n\nNext update by: Thursday, September 16, 2021, at 11:30 PM UTC",
  },

  {
    id: "EX284109",
    publishTime: "09 Sept 2021, 21:26",
    messageText:
      "Title: Users can't add shared mailboxes via the Outlook mobile app\n\nUser Impact: Users are unable to add shared mailboxes via the Outlook mobile app.\n\nMore info: Outlook desktop and Outlook on the web are unaffected.\n\nUsers will encounter the following error when attempting to add shared mailboxes via the Outlook mobile app:\n\n\"Authentication Failed: That didn't work. You may not have the right permissions to add this mailbox or the mailbox doesn't exist.\"\n\nCurrent status: We've determined that the reversion of the offending change across the affected environments requires additional validation and processing time, and we now anticipate resolution by Friday, September 10, 2021.\n\nScope of impact: Any user attempting to add shared mailboxes via the Outlook mobile app may be impacted.\n\nStart time: Tuesday, August 24, 2021, at 7:00 AM UTC\n\nEstimated time to resolve: We expect the change will be reverted and the user impact will be mitigated by Friday, September 10, 2021.\n\nRoot cause: A recent update intended to optimize the error code for disabled accounts incidentally introduced an incorrect logic and caused impact.\n\nNext update by: Friday, September 10, 2021, at 8:30 PM UTC",
  },
  {
    id: "EX284109",
    publishTime: "09 Sept 2021, 19:46",
    messageText:
      "Title: Users can't add shared mailboxes via Outlook mobile apps\n\nUser Impact: Users are unable to add shared mailboxes via Outlook mobile apps.\n\nMore info: Outlook desktop and Outlook on the web are unaffected.\n\nUsers will encounter the following error when attempting to add shared mailboxes via Outlook mobile apps:\n\n\"Authentication Failed: That didn't work. You may not have the right permissions to add this mailbox or the mailbox doesn't exist.\"\n\nCurrent status: We've identified a recent update intended to optimize the error code for disabled accounts which incidentally introduced an incorrect logic and caused impact. We're reverting this change across all affected environments which we anticipate will resolve the issue by the next scheduled update.\n\nScope of impact: Any user attempting to add shared mailboxes via the Outlook mobile apps may be impacted.\n\nStart time: Tuesday, August 24, 2021, at 7:00 AM UTC\n\nRoot cause: A recent update intended to optimize the error code for disabled accounts incidentally introduced an incorrect logic and caused impact.\n\nNext update by: Thursday, September 9, 2021, at 8:30 PM UTC",
  },
  {
    id: "EX284109",
    publishTime: "09 Sept 2021, 19:01",
    messageText:
      "Title: Users can't add shared mailboxes via Outlook mobile apps\n\nUser Impact: Users are unable to add shared mailboxes via Outlook mobile apps.\n\nMore info: Outlook desktop and Outlook on the web are unaffected.\n\nUsers will encounter the following error when attempting to add shared mailboxes via Outlook mobile apps:\n\n\"Authentication Failed: That didn't work. You may not have the right permissions to add this mailbox or the mailbox doesn't exist.\"\n\nCurrent status: We're reviewing recent service updates to isolate the source of the problem and determine the most expedient mitigation approach.\n\nScope of impact: Any user attempting to add shared mailboxes via the Outlook mobile apps may be impacted.\n\nNext update by: Thursday, September 9, 2021, at 6:30 PM UTC",
  },
  {
    id: "EX284109",
    publishTime: "09 Sept 2021, 18:46",
    messageText:
      "Title: Users can't add shared mailboxes via Outlook mobile apps\n\nUser Impact: Users are unable to add shared mailboxes via Outlook mobile apps.\n\nMore info: Outlook desktop and Outlook on the web are unaffected.\n\nCurrent status: We're investigating a potential issue and checking for impact to your organization. We'll provide an update within 30 minutes.\n\nScope of impact: Any user attempting to add shared mailboxes via the Outlook mobile apps may be impacted.",
  },
  {
    id: "EX284109",
    publishTime: "10 Sept 2021, 20:14",
    messageText:
      "Title: Users can't add shared mailboxes via the Outlook mobile app\n\nUser Impact: Users are unable to add shared mailboxes via the Outlook mobile app.\n\nMore info: Outlook desktop and Outlook on the web are unaffected.\n\nUsers will encounter the following error when attempting to add shared mailboxes via the Outlook mobile app:\n\n\"Authentication Failed: That didn't work. You may not have the right permissions to add this mailbox or the mailbox doesn't exist.\"\n\nCurrent status: The reversion of the offending change has completed the validation process, however this change will take some time to saturate across all affected environments. Users will incrementally experience mitigation as the saturation continues, and we expect completion for all users by Monday, September 13, 2021.\n\nScope of impact: Any user attempting to add shared mailboxes via the Outlook mobile app may be impacted.\n\nStart time: Tuesday, August 24, 2021, at 7:00 AM UTC\n\nEstimated time to resolve: We expect the reverted change will saturate all affected environments and mitigate the impact for all users by Monday, September 13, 2021.\n\nRoot cause: A recent update intended to optimize the error code for disabled accounts incidentally introduced an incorrect logic and caused impact.\n\nNext update by: Monday, September 13, 2021, at 10:00 PM UTC",
  },
  {
    id: "EX284109",
    publishTime: "13 Sept 2021, 22:29",
    messageText:
      "Title: Users can't add shared mailboxes via the Outlook mobile app\n\nUser Impact: Users are unable to add shared mailboxes via the Outlook mobile app.\n\nMore info: Outlook desktop and Outlook on the web are unaffected.\n\nUsers will encounter the following error when attempting to add shared mailboxes via the Outlook mobile app:\n\n\"Authentication Failed: That didn't work. You may not have the right permissions to add this mailbox or the mailbox doesn't exist.\"\n\nCurrent status: We've confirmed with affected users that the fix is incrementally remediating impact. Our fix deployment timeline has been slightly extended, and we're now expecting for the fix deployment to be complete and for impact to be resolved by Tuesday, September 14, 2021, at 10:30 PM UTC.\n\nScope of impact: Any user attempting to add shared mailboxes via the Outlook mobile app may be impacted.\n\nStart time: Tuesday, August 24, 2021, at 7:00 AM UTC\n\nEstimated time to resolve: We expect the reverted change will saturate all affected environments and mitigate the impact for all users by Tuesday, September 14, 2021.\n\nRoot cause: A recent update intended to optimize the error code for disabled accounts incidentally introduced incorrect code logic and caused impact.\n\nNext update by: Tuesday, September 14, 2021, at 10:30 PM UTC",
  },

  {
    id: "DZ284692",
    publishTime: "13 Sept 2021, 14:54",
    messageText:
      "Title: Users were unable to export a devices timeline within M365Defender.\n\nUser Impact: Users were unable to export a devices timeline within M365Defender.\n\nMore info: Affected users would have received a 'Failed to export data\" error message.\n\nFinal status: We've rolled back the change and confirmed that the issue has been resolved.\n\nScope of impact: This issue may have impacted any user trying to export a devices timeline.\n\nStart time: Monday, September 13, 2021, at 7:00 AM UTC\n\nEnd time: Monday, September 13, 2021, at 11:40 AM UTC\n\nRoot cause: A recent standard service update unexpectedly introduced a coding issue, resulting in impact to export functionality.\n\nNext steps:\n- We're reviewing our update procedures to better identify similar issues during our development and testing cycles.\n\nThis is the final update for the event.",
  },
  {
    publishTime: "13 Sept 2021, 12:45",
    messageText:
      "Title: Users are unable to export a devices timeline within M365Defender\n\nUser Impact: Users are unable to export a devices timeline within M365Defender.\n\nMore info: Affected users will receive a 'Failed to export data\" error message.\n\nCurrent status: We've identified that a recently enabled change has caused the export function to be unavailable. We're rolling back this change to resolve the issue.\n\nScope of impact: This issue may have impacted any user trying to export a devices timeline.\n\nStart time: Monday, September 13, 2021, at 7:00 AM UTC\n\nRoot cause: A recent change unexpectedly introduced a coding issue, resulting in impact to export functionality.\n\nNext update by: Monday, September 13, 2021, at 1:00 PM UTC",
  },

  {
    id: "OD280960",
    publishTime: "27 Aug 2021, 00:11",
    messageText:
      "Title: Users' OneDrive for Business storage limits are lower than expected\n\nUser Impact: Users' OneDrive for Business storage limits are lower than expected.\n\nMore info: For any quota which exceeds the 1TB storage quota, users may only be able to see their content in read-only mode.\n\nWhilst in read-only mode, users can access their content in OneDrive for Business, but are unable to make changes.\n\nCurrent status: The deployment has completed successfully. Additionally, we’ve identified that the fix will take approximately 24 hours to take full effect. We’ll continue to closely monitor the environment during this timeframe to ensure the service remains in a healthy state, and that the fix progresses as intended. \n\nScope of impact: This issue affects a large subset of users worldwide. \n\nEstimated time to resolution: Friday, August 27, 2021, at 10:00 PM UTC\n\nPreliminary root cause: A misconfiguration that resulted in our internal systems not recognizing user licenses and reverting the storage quota limit to the default settings of 1TB.\n\nNext update by: Friday, August 27, 2021, at 11:00 PM UTC",
  },
  {
    id: "OD280960",
    publishTime: "26 Aug 2021, 23:00",
    messageText:
      "Title: Users' OneDrive for Business storage limits are lower than expected\n\nUser Impact: Users' OneDrive for Business storage limits are lower than expected.\n\nMore info: For any quota which exceeds the 1TB storage quota, users may only be able to see their content in read-only mode.\n\nWhilst in read-only mode, users can access their content in OneDrive for Business, but are unable to make changes.\n\nCurrent status: The targeted deployment remediated impact as expected, and we’re in the process of expanding the deployment to all affected users. We’ll continue to monitor the environment while the fix saturates to ensure it completes successfully.\n\nScope of impact: This issue affects a larger subset of users worldwide.\n\nPreliminary root cause: An error occurred resulting in the system not recognizing user licenses and reverting the storage quota limit to the default settings of 1TB.\n\nNext update by: Thursday, August 26, 2021, at 11:00 PM UTC",
  },
  {
    id: "OD280960",
    publishTime: "26 Aug 2021, 20:57",
    messageText:
      "Title: Users' OneDrive for Business storage limits are lower than expected\n\nUser Impact: Users' OneDrive for Business storage limits are lower than expected.\n\nMore info: For any quota which exceeds the 1TB storage quota, users may only be able to see their content in read-only mode.\n\nWhilst in read-only mode, users can access their content in OneDrive for Business, but are unable to make changes.\n\nWe'd like to get your feedback on the workaround we provided. From a non-mobile device, please rate this post and submit it in the textbox.\n\nCurrent status: We’ve initiated the deployment of the fix in a limited environment to ensure it resolves impact as expected. Once confirmed, we’ll proceed with expanding the deployment to all affected users. By design, this fix is expected to restore all affected users to the default storage quota of five terabytes.\n\nScope of impact: Further investigation has identified that this issue may impact users outside of the previously communicated timeframe, we apologize for any inconvenience or confusion this may have caused. Currently, our telemetry indicates that this issue affects a larger subset of users worldwide, including those not within the aforementioned criteria. \n\nPreliminary root cause: An exception is not recognizing user licenses and reverting the storage quota limit to the default settings of 1TB.\n\nNext update by: Thursday, August 26, 2021, at 9:00 PM UTC",
  },
  {
    id: "OD280960",
    publishTime: "26 Aug 2021, 19:59",
    messageText:
      "We're continuing to monitor customer feedback and are adjusting our messaging in an effort to make it more clear.\n\nOur understanding of the issue is evolving and real time customer feedback is invaluable and appreciated. From a non-mobile device, please rate this post and let us know if our updates have provided greater clarity via the text box.\n\nThis quick update is designed to give the latest information on this issue.",
  },
  {
    id: "OD280960",
    publishTime: "26 Aug 2021, 19:25",
    messageText:
      "Title: Users' OneDrive for Business storage limits are lower than expected\n\nUser Impact: Users' OneDrive for Business storage limits are lower than expected.\n\nWe've determined that the previously provided workaround is not viable or functioning as expected for all affected users and have removed that guidance from this message. We apologize for any inconvenience or confusion this may have caused.\n\nThis quick update is designed to give the latest information on this issue.",
  },
];
export async function getInitialData(setState: any) {
  const mockData = mockServiceStatus;
  const alphSortedData = [...mockData].sort((a: IItems, b: IItems) =>
    a.services.localeCompare(b.services)
  );
  const incidentSort = [...alphSortedData].sort(
    (a: IItems, b: IItems) => b.messages.length - a.messages.length
  );
  setState(incidentSort);
}

const dateConverterWithoutTime = (datestring: string) => {
  const date = new Date(datestring);
  const convertedDate = date.toLocaleString("default", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return convertedDate ? convertedDate : "";
};

export async function getMessagesData(setState: any, messageId: string) {
  const rawData = [{}];
  const prettyData = rawData.map((data: any) => {
    return {
      publishTime: dateConverterWithTime(data.publishedTime),
      messageText: data.messageText,
    };
  });

  const mockData = mockMessageData.filter(
    (messages: any) => messages.id === messageId
  );
  setState(mockData);
}

const dateConverterWithTime = (datestring: string) => {
  const date = new Date(datestring);
  const convertedDate = date.toLocaleString("default", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return convertedDate;
};
