import { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { getToken } from "../../../TeamsApp/helper";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { IHistoryItem, IHistoryMessage } from "../../../models/models";
import { RenderMessages } from "../../../commonComponents/MessageListItem";
import { HistoryListItem } from "../../../commonComponents/HistoryListItem";
import { getMessagesData } from "../../../TeamsApp/incidentsTab/utils/helper";

interface MobileList {
  items: IHistoryItem[];
  selectedService: IHistoryItem | undefined;
  setSelectedService: any;
  setSecondLevelmessages: any;
  setTitle: any;
  setStatusDisplayName: any;
}

export const MobileList = ({
  items,
  selectedService,
  setSelectedService,
  setSecondLevelmessages,
  setTitle,
  setStatusDisplayName,
}: MobileList) => {
  const [context, setContext] = useState<microsoftTeams.Context>();
  const [token, setToken] = useState<string>("");
const [parent] = useAutoAnimate()
  microsoftTeams.initialize();
  useEffect(() => {
    
    getToken(setToken);
    microsoftTeams.getContext((context: microsoftTeams.Context) => {
      setContext(context);
    });
  }, []);

  const handleMessageClick = (message: IHistoryMessage) => {
    if (context && token) {
      getMessagesData(setSecondLevelmessages, message.id, context, token);
    }
    setTitle(message.title);
    setStatusDisplayName(selectedService?.status);
  };

  const handleOnClick = (item: IHistoryItem) => {
    if (selectedService?.id === item.id) {
      setSelectedService(undefined);
    } else {
      setSelectedService(item);
    }
  };

  return (
      <div >        
        <h2 className="my-8 text-3xl text-center text-sky-700">Service Status</h2>
        <ul className="max-w-screen-sm m-auto">
          {items.map((item, index) => {
            return (
              <li ref={parent} className="mb-2 " key={item.id} tabIndex={index}>
                <HistoryListItem item={item} handleClick={handleOnClick} isOpen={selectedService?.id === item.id} />
                  {selectedService?.id === item.id
                    ? <RenderMessages messages={selectedService.messages!} handleClick={handleMessageClick} />
                    : null}
              </li>
            );
          })}
        </ul>
      </div>
  );
}

