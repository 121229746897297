import { AxiosRequestConfig } from "axios";

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}


export const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
};