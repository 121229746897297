import { useEffect, useState } from "react";
import {
  Dropdown,
  IconButton,
  IDropdownOption,
  SearchBox
} from "@fluentui/react";
import Header from "../../commonComponents/Header";
import DownloadButton from "../../commonComponents/DownloadButton";
import { HistoryCardComponent } from "../../commonComponents/CardComponent";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "react-query";
import { generateArrayOfYears, prepareCSVData } from "../../helpers/helpers";
import { IAzureHistoryItem } from "../../models/models";
import { CloudHubDivider } from "../../commonComponents/Divider";
import { IHistoryRequest } from "../../api/models";
import { fetchHistory } from "../../api/history.service";
import { useTeamsContext } from "../../context/teamsContext";
import { app } from "@microsoft/teams-js";




export const AzureHistoryIndex = () =>  {
  const {getTenantId} = useTeamsContext()
  const currentYear = new Date().getFullYear()
    const [selectedYear, setSelectedYear] = useState<IDropdownOption>({key: currentYear, text: currentYear.toString()})
    const [filterText, setFilterText] = useState<string>("")
    const [parent] = useAutoAnimate()
    const params:IHistoryRequest = {
        TenantId: getTenantId && getTenantId() || "" ,
    }

    const {isLoading, data} = useQuery<IAzureHistoryItem[]>(['news', params], () => fetchHistory(params));
    const [filteredItems, setFilteredItems] = useState<IAzureHistoryItem[]>([])
    const yearsArr:IDropdownOption[] = generateArrayOfYears()
    const handleYearChange = (option:IDropdownOption) => {
      setSelectedYear(option)        
    }
    const filterItems = () => {
        setFilteredItems(getFilteredItems())
    };
    const getFilteredItems = () => {
        if (!data) return [];
       return data.filter((item) => {         
            return (selectedYear.key !== 0 ? item.date.includes(selectedYear.key.toString()): true) && (filterHelper(item.title) || filterHelper(item.description) )
        })
    }
    const filterHelper = (text: string) => {
        return text.toLowerCase().includes(filterText.toLowerCase())
    }
    
    const handleKeyWordFilterChange = (text:string) => {
        setFilterText(text)
    }
   
    const handleReset = () => {
        setSelectedYear({key: currentYear, text: currentYear.toString()})
        setFilterText("")
    }

    
    useEffect(() => {
        filterItems()
    }, [data, filterText, selectedYear])
    if(!isLoading)  app.notifySuccess()
  return (
    <div className="relative">
      <Header title="Azure History" icon="FullHistory" />
      <div className="flex justify-between w-full max-w-screen-lg px-4 m-auto">
        <div className="">
          <Dropdown
            options={yearsArr}
            onChange={(e, option) => handleYearChange(option!)}
            label="Year"
            selectedKey={selectedYear.key}
            placeholder={currentYear.toString()}
          />
        </div>
        <div className="flex items-end">
          <div className="ml-2">
              <label className="py-1 pl-0 m-0 text-sm text-semibold">Keyword</label>
              <SearchBox
                  placeholder="Search... "
                  onChange={(e, text) => handleKeyWordFilterChange(text ?? "")}
                  value={filterText}
                  className="transition duration-100 ease-in-out border-sky-700 hover:border-sky-900 focus:outline-nonefocus:border-sky-900 hover:bg-sky-50 focus:bg-sky-50 "
                  // onSearch={handleSubmit}
                  onClear={() => handleKeyWordFilterChange("")}
              />
          </div>
          <div className="flex items-end">
            {/* <BasicButton handleClick={handleSubmit}>Search</BasicButton> */}
            <IconButton
                iconProps={{ iconName: "ClearFilter" }}
                onClick={handleReset}
                className="ml-2 font-semibold text-white transition duration-100 ease-in-out rounded bg-sky-700 hover:bg-sky-900"
                title="Reset all filters"
                ariaLabel="Reset all filters"
              />
            <DownloadButton
              csvData={prepareCSVData(filteredItems)}
              csvFilename="azureHistory.csv"
            />
          </div>
          
        </div>
      </div>

      <CloudHubDivider count={filteredItems.length} />
      <div className="flex flex-col w-full max-w-lg px-4 py-2 m-auto md:max-w-screen-lg" ref={parent}>
        { filteredItems.length > 0 ? filteredItems.map(data => <HistoryCardComponent data={data} key={data.title}/>) : <p className="my-12 text-xl text-center text-sky-700">No results found</p>}        
      </div>
       
      
    </div>
  );
}
