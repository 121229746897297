import React from "react";
import ReactDOM from "react-dom";
import "./fabric-icons.css";
import "./index.css";
import {App} from "./TeamsApp/App";
import { initializeIcons } from "@fluentui/react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query"
import axios from "axios";
import { TeamsContextProvider } from "./context/teamsContext";
import { getNewAxiosToken } from "./hooks/useAuth";
// import { getNewGraphToken } from "./hooks/useScopedAuth";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.request && error.request.status === 401) {
      // Set Failed Request
      let failedRequest = error.config;
      if (!failedRequest || !failedRequest.retry) {
        return Promise.reject(failedRequest);
      }
      failedRequest.retry -= 1
      //Method to get new token
      return getNewAxiosToken().then(response => {
        // Set axios instance header
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response;
        failedRequest.headers['Authorization'] = 'Bearer ' + response;
        //Retry failed request with delay
        const delayRetryRequest = new Promise<void>((resolve) => {
          setTimeout(() => {
            console.log("retry the request", failedRequest.url);
            resolve();
          }, failedRequest.retryDelay || 1000)
        })
        return delayRetryRequest.then(() =>  axios.request(failedRequest));
      });
    }
    return Promise.reject(error);
  },
)


export const graphAxios = axios.create();
graphAxios.defaults.baseURL = "https://graph.microsoft.com/v1.0/";
// graphAxios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error.request && error.request.status === 401) {
//       // Set Failed Request
//       let failedRequest = error.config;
//       if (!failedRequest || !failedRequest.retry) {
//         return Promise.reject(failedRequest);
//       }
//       failedRequest.retry -= 1
//       //Method to get new token
//       return getNewGraphToken().then(response => {
//         // Set axios instance header
//         console.log("graph Token")
//         graphAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response;
//         failedRequest.headers['Authorization'] = 'Bearer ' + response;
//         //Retry failed request with delay
//         const delayRetryRequest = new Promise<void>((resolve) => {
//           setTimeout(() => {
//             console.log("retry the request", failedRequest.url);
//             resolve();
//           }, failedRequest.retryDelay || 1000)
//         })
//         return delayRetryRequest.then(() =>  graphAxios.request(failedRequest));
//       });
//     }
//     return Promise.reject(error);
//   },
// )


const queryClient = new QueryClient()
initializeIcons()
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>    
      <QueryClientProvider client={queryClient}>
        <TeamsContextProvider>
          <App />
        </TeamsContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
