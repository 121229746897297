import {
  CompactPeoplePicker,
  IBasePickerSuggestionsProps,
  IBaseExtendedPickerProps,
  Stack,
} from "@fluentui/react";
import styles from "./PeoplePicker.module.css";
import { getSearchPeople } from "../../../api/message.service";
import { IPersonaProps } from 'office-ui-fabric-react';
import { useEffect, useState } from "react";
import { set } from "react-ga";
interface PeoplePickerProps {
  type: string
  handleSelectPeople: (recipients: Person[]) => void;
}
export interface Person {
  text: string;
  id: string;
  email: string;
}

export default function PeoplePicker({ handleSelectPeople, type }: PeoplePickerProps) {

  const title = `People you want to write a ${type === "chat" ? "Chat" : "Mail"}`
  const onFilterChanged = async (filterText: string, recipient: IPersonaProps[]) => {
    if (filterText && filterText !== "") {
      const response = await getSearchPeople(filterText);
      if (response && response.length > 0) {
        const filteredResponse = response.filter(function (el) {
          return !recipient.find((item) => item.id === el.id);
        });
        return filteredResponse || []
      }
      return []
    }
    return []
  };

  const handleChange = async (recipients: Person[]) => {

    // Prevent duplicates - error if Chat send to same person
    const seen = new Set();
    const filtered = recipients.filter(item => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    })
    handleSelectPeople(filtered);
  };



  return (
    <div className={styles.peoplePicker}>
      <Stack horizontal verticalAlign="center">
        <i className="ms-Icon ms-Icon--Mail mr-2" />
        <span className="font-semibold">{title}</span>
      </Stack>

      <CompactPeoplePicker
        onResolveSuggestions={async (filterText: any, recipient: any) => await onFilterChanged(filterText, recipient)}
        pickerSuggestionsProps={suggestionProps}
        getTextFromItem={(persona: IPersonaProps) => persona.text || ""}
        enableSelectedSuggestionAlert={true}
        onChange={(recipient: any) => handleChange(recipient)}
        key={'normal'}
      />
    </div>
  );
}

const suggestionProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: "No results found",
  loadingText: "Loading",
  suggestionsAvailableAlertText: "People Picker Suggestions available"

};
