import { useEffect, useState } from "react";
import { graphAxios } from "..";
import { useTeamsContext } from "../context/teamsContext";
import { authentication } from "@microsoft/teams-js";
import axios from "axios";


export interface ITokenOnBehalfModel {
  succeeded: boolean;
  token: string;
  error: string;
}

export const useScopedAuth = () => {

  const { handleSetNeedConstent } = useTeamsContext()
  const [initToken, setInitToken] = useState<boolean>(false);
  const [isTokenSet, setIsTokenSet] = useState<boolean>(false);


  const handleInitToken = () => {
    setInitToken(true)
  }


  const getAccessToken = async () => {
    const res = await axios.post(`/GetOnBehalfTokenForUser`);
    const data = res.data as ITokenOnBehalfModel

    if (data.succeeded) {

      handleSetToken(data.token)
    }
    else {
      console.log(data.error);
      showConsentDialog();
    }
  }



  const getTokenOnBehalfAfterConsent = async () => {
    const res = await axios.post(`/GetOnBehalfTokenForUser`);
    const data = res.data as ITokenOnBehalfModel
    if (data.succeeded) {
      handleSetToken(data.token)
    }
    else {
      handleSetNeedConstent && handleSetNeedConstent(true)
    }
  }

  const showConsentDialog = () => {

    authentication.authenticate({
      url: window.location.origin + `/auth-start`,
      width: 600,
      height: 535
    }).then((result) => {
      consentSuccess(result)
    }).catch((error) => {
      consentFailure(error)
    });
  }

  const consentSuccess = (result: string) => {
    handleSetToken(result)
  }

  const consentFailure = (reason: string) => {
    if (reason != "response empty") {
      handleSetNeedConstent && handleSetNeedConstent(true)
    }
    else {
      getTokenOnBehalfAfterConsent()
    }
  }

  const handleSetToken = async (token: string) => {
    graphAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setIsTokenSet(true)
    setInitToken(false)
  }

  useEffect(() => {
     if (initToken) getAccessToken()
  }, [initToken]);
  return { handleInitToken, isTokenSet }
}

export const getNewGraphToken = async () => {
  return new Promise((resolve) => {
    
      axios.post(`/GetOnBehalfTokenForUser`).then((res) => {
        const data = res.data as ITokenOnBehalfModel
        if (data.succeeded) {
          resolve(data.token);
        }
        else {
          console.log(data.error);
          resolve(null);
        }
      });
    });

}