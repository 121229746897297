export const desktopColumns = [
  {
    key: "isFavorite",
    name: "isFavorite",
    fieldName: "isFavorite",
    minWidth: 30,
    maxWidth: 30,
    isResizable: true,
    isIconOnly: true,
  },
  {
    key: "Message title",
    name: "Message title",
    fieldName: "messageTitle",
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isRowHeader: true,
    minWidth: 120,
    maxWidth: 500,
    isResizable: true,
  },
  {
    key: "more",
    name: "more",
    fieldName: "more",
    minWidth: 30,
    maxWidth: 30,
    isResizable: true,
    isIconOnly: true,
  },
  {
    key: "Service",
    name: "Service",
    fieldName: "service",
    minWidth: 100,
    maxWidth: 400,
    isResizable: true,
  },
  {
    key: "Start Time",
    name: "Start Time",
    fieldName: "startTime",
  
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isRowHeader: true,
    minWidth: 120,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "End Time",
    name: "End Time",
    fieldName: "endTime",
  
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isRowHeader: true,
    minWidth: 120,
    maxWidth: 150,
    isResizable: true,
  },
  
  {
    key: "Message ID",
    name: "Message ID",
    fieldName: "id",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "Category",
    name: "Category",
    fieldName: "category",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "Severity",
    name: "Severity",
    fieldName: "severity",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
];
export const mobileColumns = [
  {
    key: "isFavorite",
    name: "isFavorite",
    fieldName: "isFavorite",
    minWidth: 30,
    maxWidth: 30,
    isResizable: true,
    isIconOnly: true,
  },
  {
    key: "Message title",
    name: "Message title",
    fieldName: "messageTitle",
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isRowHeader: true,
    minWidth: 120,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "Start Time",
    name: "Start Time",
    fieldName: "startTime",
  
    sortAscendingAriaLabel: "Sorted A to Z",
    sortDescendingAriaLabel: "Sorted Z to A",
    isRowHeader: true,
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "Service",
    name: "Service",
    fieldName: "service",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "more",
    name: "more",
    fieldName: "more",
    minWidth: 30,
    maxWidth: 30,
    isIconOnly: true,
  },
];
  