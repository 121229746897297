import { IDropdownOption } from "@fluentui/react"
import { IAzureHistoryItem, IHistoryItem, IMessage } from "../models/models";


export const convertHTMLToText = (html: string) => {
  // Create a new div element
  let tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}



export const formatDate = (DateString: string) => {
  let date = new Date(DateString);
  const convertedDate = date.toLocaleString("default", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return convertedDate
};
export const formatDateTime = (dateTimeString: string): string => {
  const dateTime = new Date(dateTimeString);
  const year = dateTime.getFullYear();
  let month = dateTime.getMonth() + 1;
  let day = dateTime.getDate();
  let hour = dateTime.getHours();
  let minute = dateTime.getMinutes();
  const parsedDay = day < 10 ? `0${day}` : day.toString();
  const parsedmonth = month < 10 ? `0${month}` : month;
  const parsedhours = hour < 10 ? `0${hour}` : hour;
  const parsedminute = minute < 10 ? `0${minute}` : minute;
  const formattedDate = `${year}-${parsedmonth}-${parsedDay} ${parsedhours}:${parsedminute}`;
  return formattedDate;
  const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',};
  return dateTime.toLocaleDateString(undefined, options);
};

  export const generateArrayOfYears = () => {
    const max = new Date().getFullYear();
    const min = 2019;
    const years: { key: number; text: string }[] = [];
  
    for (let i = max; i >= min; i--) {
      years.push({ key: i, text: i.toString() });
    }
    return years;
  };

  export const prepareCSVData = ( filteredItems: IAzureHistoryItem[] ) => 
    filteredItems.map( item => {
            return {
              Date: item.date,
              Title: item.title,
              Message: convertHTMLToText(item.description).replaceAll('"', "''"),
            };
          }
        )
      
  
  

  
  export const handleOpenLink = (e:React.MouseEvent<HTMLAnchorElement> ) => {
    e.preventDefault();
    const url = e.currentTarget.href;
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure you want to open this link?")) {
        window.open(url, "_blank");
      }
  }
/*   id: string;
  service: string;
  issues?: IIncidentItem[]  
  status: string;
  messages?: IHistoryMessage[]; */
   export const prettifyIncidents = (array: []) : IHistoryItem[]=> {
    return array.map((data:any) => {
      return {
        id: data.id,
        service: data.workloadDisplayName,
        messages: data.messages.map((message: any) => {
          return {
            lastUpdatedTime: formatDate(message.lastUpdatedTime),
            startTime: formatDate(message.startTime),
            title: message.title,
            id: message.id,
          };
        }),
        status: data.status,
        statusDisplayName: data.statusDisplayName,
      };
    })
  } 

export const sortIncidents = (array: IHistoryItem[]) => {
  const statusSortOrder = new Map([  ["serviceInterruption", 1],
    ["postIncidentReviewPublished", 2],
    ["serviceDegradation", 3],
    ["extendedRecovery", 4],
    ["serviceRestored", 5],
    ["serviceOperational", 6],
  ]);

  return [...array].sort((a: IHistoryItem, b: IHistoryItem) => {
    const sortOrderA = statusSortOrder.get(a.status) || Infinity;
    const sortOrderB = statusSortOrder.get(b.status) || Infinity;
    return sortOrderA - sortOrderB;
  });
}
  
  export const prettifyMessages = (array: []) => array.map((data: any) => {
    return {
      messageTitle: data.title,
      service: data.affectedWorkloadDisplayNames.split(","),
      startTime: formatDateTime(data.startTime),
      endTime: formatDateTime(data.endTime),
      lastUpdatedTime: formatDateTime(data.lastUpdatedTime),
      id: data.id,
      category: data.category,
      severity: data.severity,
      messages: data.messages,
      isArchived: data.isArchivedInApp,
      isFavorite: data.isFavoriteInApp,
      isRead: data.isReadInApp,
    };
  });

  export const sortMessages = (messages: IMessage[]) => [...messages].sort((a, b) =>  +new Date(b.lastUpdatedTime) - +new Date(a.lastUpdatedTime));



  export const getDaysArr = (): IDropdownOption[] => {    
    return [
        {
            key: 7,
            text: "last week"
        },
        {
            key: 14,
            text: "last 2 weeks"
        },
        {
            key: 30,
            text: "last month"
        },
        {
            key: 90,
            text: "last 3 months"
        },
        {
            key: 180,
            text: "last 6 months"
        },
    ]
}


export const compareHelper = (servicename: string, item: string) => servicename.toLowerCase() === item.toLowerCase();


export const markItemAsRead = (items: IMessage[], item: IMessage) => {
  return items.map((obj) => {
    if (obj.id === item.id) {
      obj.isRead = true;
      return obj;
    } else {
      return obj;
    }
  });
};
export const markItemAsFavorite = (items: IMessage[], item: IMessage) => {
  return items.map((obj) => {
    if (obj.id === item.id) {
      obj.isFavorite = !obj.isFavorite;
      return obj;
    } else {
      return obj;
    }
  });  
};
export const markItemAsArchived = (items: IMessage[], item: IMessage) => {
  return items.map((obj) => {
    if (obj.id === item.id) {
      obj.isArchived = !obj.isArchived;

      return obj;
    } else {
      return obj;
    }
  });  
};