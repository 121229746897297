import { IHistoryItem } from "../models/models";


export const StatusIcon = ({item}:{item: IHistoryItem}) => {
    let status = item.status.toLowerCase();
    if(status === "serviceoperational" || status === "servicerestored") return <Icon name="SkypeCircleCheck" />
    if(status === "serviceinterruption" || status === "postincidentreviewpublished") return <Icon name="IncidentTriangle" />
    if(status === "extendedrecovery" || status === "servicedegradation") return <Icon name="InfoSolid" />
    return <Icon name="SkypeCircleCheck" />
  };

  const Icon = (props: { name: string }) => (
    <div className="flex items-center justify-center">
      <i className={`ms-Icon ms-Icon--${props.name}`} aria-hidden="true"></i>
    </div>
  );
  
