import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "react-query";
import { fetchAzurePage } from "../../api/status.service";
import { useTeamsContext } from "../../context/teamsContext";
import {  app,  } from "@microsoft/teams-js";

export default function AzureStatus() {

  const {getBasicUserData} = useTeamsContext()
  const paramsBase = getBasicUserData && getBasicUserData() 
  const params = {
    ...paramsBase,
    AzurePage: "htmlAzureStatus.html",
  }
  const {data, isLoading} = useQuery(["azureStatus", params.AzurePage], () => fetchAzurePage(params));

  
  const [myRef] = useAutoAnimate();
  if(!isLoading)  app.notifySuccess();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: data }}
      ref={myRef}    
    >
    </div>  
  );
}
