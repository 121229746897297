import { useEffect, useState } from "react";
import styles from "./azureHistoryDemo.module.css";
import {
  filterFunction,
  GetAzureHistroyData,
  highlight,
  IAzureHistoryItem,
  prepareCSVData,
  removeHighlight,
  generateArrayOfYears,
} from "./helper";
import { SearchBox } from "@fluentui/react";
import {
  Dropdown,
  IconButton,
  IDropdownOption,
  PrimaryButton,
  Separator,
} from "@fluentui/react";
import DownloadButton from "../../commonComponents/DownloadButton";
import Header from "../../commonComponents/Header";

export default function AzureHistoryDemo() {
  const [rawData, setRawData] = useState<IAzureHistoryItem[]>([]);
  const [displayData, setDisplayData] = useState<IAzureHistoryItem[] | []>();
  const [keyWordFilterValue, setKeyWordFilterValue] = useState<string>("");
  const [isErrorMessageHidden, setIsErrorMessageHidden] =
    useState<boolean>(true);
  const [date, setDate] = useState<IDropdownOption | undefined>({
    key: 0,
    text: "All years",
  });
  useEffect(() => {
    GetAzureHistroyData(setRawData);
  }, []);

  const handleKeyWordFilterChange = (newValue: string) => {
    setKeyWordFilterValue(/^\s/.test(newValue) ? "" : newValue);
    setIsErrorMessageHidden(true);
  };

  const handlesYearFilterChange = (date: IDropdownOption | undefined) => {
    setDate(date);
    if (keyWordFilterValue.length > 0 && keyWordFilterValue.length < 3) {
      filterFunction("", date!.text, rawData, setDisplayData);
    } else {
      filterFunction(keyWordFilterValue, date!.text, rawData, setDisplayData);
    }
    setTimeout(function () {
      removeHighlight();
      highlight(keyWordFilterValue);
    }, 200);
  };
  const handleSearch = () => {
    if (keyWordFilterValue.length > 0 && keyWordFilterValue.length < 3) {
      setIsErrorMessageHidden(false);
    } else {
      filterFunction(keyWordFilterValue, date!.text, rawData, setDisplayData);

      if (keyWordFilterValue.length === 0) {
        removeHighlight();
      }
      setTimeout(function () {
        removeHighlight();
        highlight(keyWordFilterValue);
      }, 200);
    }
  };
  const handleDelete = () => {
    removeHighlight();
    setKeyWordFilterValue("");
    filterFunction("", date!.text, rawData, setDisplayData);
  };
  const handleReset = () => {
    setDisplayData(rawData);
    setKeyWordFilterValue("");
    setDate({
      key: 0,
      text: "All years",
    });
    removeHighlight();
  };

  return (
    <>
      <Header title="Azure History" icon="FullHistory" />
      <main className={styles.main}>
        <section className={styles.inputs}>
          <Dropdown
            options={generateArrayOfYears()}
            className={styles.dropdown}
            onChange={(e, text) => handlesYearFilterChange(text)}
            label="Year"
            selectedKey={date ? date.key : 0}
          />
          <div className={styles.keywordContainer}>
            <p>Keyword</p>
            <div>
              <SearchBox
                placeholder="Search... "
                onChange={(e, text) => handleKeyWordFilterChange(text ?? "")}
                value={keyWordFilterValue}
                labelText="Filter by Keyword"
                onSearch={() => handleSearch()}
                onClear={() => handleDelete()}
                className={styles.searchbox}
              />
              <PrimaryButton text="Search" />
              <IconButton
                iconProps={{ iconName: "ClearFilter" }}
                onClick={handleReset}
                className={styles.IconButton}
                title="Reset all filters"
                ariaLabel="Reset all filters"
              />
              <DownloadButton
                csvData={prepareCSVData(displayData, rawData)}
                csvFilename="azureHistory.csv"
              />
            </div>
            <p className={styles.errorMessage}>
              {isErrorMessageHidden
                ? null
                : "Keyword must be at least 3 letters long. Actual length is " +
                  keyWordFilterValue.length +
                  "."}
            </p>
          </div>
        </section>

        {displayData ? (
          <>
            <Separator className={styles.seperator}>
              {displayData?.length}
              {displayData?.length === 1 ? <> Item</> : <> Items</>}
            </Separator>
            <RenderItems displayData={displayData as IAzureHistoryItem[]} />
          </>
        ) : (
          <>
            <Separator className={styles.seperator}>
              {rawData?.length} Items
            </Separator>
            <RenderItems displayData={rawData as IAzureHistoryItem[]} />
          </>
        )}
      </main>
      {rawData?.length > 0 ? (
        <footer className={styles.footer}>
          <span>
            Source: provided by Microsoft (
            <a
              href={"https://status.azure.com/en-gb/status/history"}
              target="_blank"
              rel="noreferrer noopener"
            >
              https://status.azure.com/en-gb/status/history
            </a>
            )
          </span>
        </footer>
      ) : null}
    </>
  );
}

interface renderItemsProps {
  displayData: IAzureHistoryItem[];
}

const formatDate = (DateString: string) => {
  let date = new Date(DateString);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
  return day + " " + month + " " + year;
};

function RenderItems({ displayData }: renderItemsProps) {
  return (
    <section className={styles.items}>
      {displayData.map((item, i) => (
        <div key={i} className={styles.itemscontainer}>
          <div className={styles.date + " searchArea"}>
            {formatDate(item.date)}
          </div>
          <div>
            <h3 className="searchArea">{item.title}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: item.description }}
              className="searchArea"
            />
          </div>
        </div>
      ))}
    </section>
  );
}
