
import { app } from "@microsoft/teams-js";
import { createContext, useContext, useState } from "react";
import { IRequestBase } from "../api/models";
import { IIsLoggedIn } from "../models/models";

type TeamsContextValue = {
  context: app.Context | undefined;
  getTenantId: () => string;
  getUserPrincipalName: () => string;
  getTeamsSiteDomain: () => string;
  getBasicUserData: () => IRequestBase;
  handleSetContext:(context: app.Context) => void
  loggedIn: IIsLoggedIn |null,
  handleSetIsLoggedIn: (isLoggedIn: IIsLoggedIn) => void
  needConsent: boolean,
  handleSetNeedConstent: (needConsent: boolean) => void
};

const TeamsContext = createContext<Partial<TeamsContextValue>>({
    context: {} as app.Context,
});

type TeamsContextProviderProps = {
  children: React.ReactNode;
};

const TeamsContextProvider: React.FC<TeamsContextProviderProps> = ({ children }) => {
  const [context, setContext] = useState<app.Context>();
  const [loggedIn, setLoggedIn] = useState<IIsLoggedIn |null>(null);
  
  const [needConsent, setNeedConsent] = useState<boolean>(false);
  const handleSetNeedConstent = (needConsent: boolean) => {
    setNeedConsent(needConsent)
  }
  const handleSetIsLoggedIn = (isLoggedIn: IIsLoggedIn) => {
    setLoggedIn(isLoggedIn)
  }
  // Define the function that will update the context value
  const getTenantId = () => context?.user?.tenant?.id ?? "";
  const getUserPrincipalName = () => context?.user?.userPrincipalName ?? "";
  const getTeamsSiteDomain = () => context?.sharePointSite?.teamSiteDomain ?? "";
    const handleSetContext = (context: app.Context) => {
        setContext(context);
    }
    const getBasicUserData = () => {
      return {
        TenantId: context?.user?.tenant?.id ?? "",
        TeamSiteDomain: context?.sharePointSite?.teamSiteDomain ?? "",
        UserPrincipalName: context?.user?.userPrincipalName ?? "",
      }
    }
    
  const contextValue: TeamsContextValue = {
    context,
    loggedIn,
    handleSetIsLoggedIn,
    needConsent,
    handleSetNeedConstent,
    getBasicUserData,
    handleSetContext,
    getTenantId,
    getUserPrincipalName,
    getTeamsSiteDomain,
  };
  
  return <TeamsContext.Provider value={contextValue}>{children}</TeamsContext.Provider>;
};

export { TeamsContext, TeamsContextProvider };

export const useTeamsContext = () => {
    const { context, handleSetContext, getTenantId,getTeamsSiteDomain, getUserPrincipalName , getBasicUserData, loggedIn, handleSetIsLoggedIn, needConsent,
      handleSetNeedConstent, } = useContext(TeamsContext);
  
    return {
      context,
      handleSetContext,
      getTenantId,
      getTeamsSiteDomain,
      getUserPrincipalName,
      getBasicUserData,
      loggedIn,
      handleSetIsLoggedIn,
      needConsent,
      handleSetNeedConstent
    };
  };