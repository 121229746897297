import { getInitialData } from "../utils/helper";
import "./IncidentCenterDemo.css";
import Popup from "./Popup";
import { IItems, ISecondLevelmessages } from "../utils/interfaces";
import SideBar from "./SideBar";
import { useEffect, useState, useMemo, useLayoutEffect } from "react";
import * as React from "react";
import {
  CheckboxVisibility,
  DetailsList,
  IColumn,
  mergeStyleSets,
  SelectionMode,
  Selection,
  MarqueeSelection,
} from "@fluentui/react";
import Header from "../../../commonComponents/Header";
import { MobileList } from "./MobileList";
import { IHistoryItem } from "../../../models/models";

export default function IncidentCenterDemo() {
  const [items, setItems] = useState<IHistoryItem[]>();
  const [selectedService, setSelectedService] = useState<IHistoryItem>();
  const [selectedItems, setSelectedItems] = useState<any>();
  const [secondLevelmessages, setSecondLevelmessages] = useState<
    ISecondLevelmessages[] | undefined
  >();
  const [title, setTitle] = useState<string>("");
  const [statusDisplayName, setStatusDisplayName] = useState<string>("");
  const [windowWidth] = useWindowSize();
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  useEffect(() => {
    getInitialData(setItems);
  }, []);
  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          setSelectedItems(selection.getSelection());
        },
        selectionMode: SelectionMode.single,
      }),
    []
  );
  useEffect(() => {
    if (selectedItems) {
      setSelectedService(selectedItems[0]);
    }
  }, [selectedItems]);
  const detailsList = useMemo(
    () => (
      <MarqueeSelection selection={selection}>
        <DetailsList
          items={items ? items : new Array<IHistoryItem>()}
          className="detail-list"
          columns={columns}
          setKey="single"
          selection={selection}
          selectionMode={SelectionMode.single}
          checkboxVisibility={CheckboxVisibility.hidden}
          onRenderItemColumn={(item: any, index: any, column: any) =>
            renderColumns(item, index, column)
          }
          selectionPreservedOnEmptyClick={true}
        />
      </MarqueeSelection>
    ),
    [selection, columns, items]
  );

  if (!items) {
    return null;
  }

  return (
    <>
      <Header title="Incident Center" icon="HealthSolid" />
      {windowWidth > 1000 ? (
        <div className="container">
          {detailsList}
          <SideBar
            selectedService={selectedService}
            setIsPopUpOpen={setIsPopUpOpen}
            setSecondLevelmessages={setSecondLevelmessages}
            setTitle={setTitle}
            setStatusDisplayName={setStatusDisplayName}
          />
        </div>
      ) : (
        <MobileList
          items={items}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          setSecondLevelmessages={setSecondLevelmessages}
          setTitle={setTitle}
          setStatusDisplayName={setStatusDisplayName}
        />
      )}
      <Popup
        isPopUpOpen={isPopUpOpen}
        setIsPopUpOpen={setIsPopUpOpen}
        messages={secondLevelmessages}
        setMessages={setSecondLevelmessages}
        title={title}
        statusDisplayName={statusDisplayName}
      />
    </>
  );
}

const classNames = mergeStyleSets({
  fileIconCell: {
    textAlign: "left",
    marginLeft: "9px",
  },
});

const columns = [
  {
    key: "Services",
    name: "Service Status",
    fieldName: "services",
    minWidth: 0,
    maxWidth: 0,
    isResizable: true,
  },
  {
    key: "Status",
    name: "",
    fieldName: "status",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    className: classNames.fileIconCell,
  },
];

const renderColumns = (item: IItems, index: number, column: IColumn) => {
  const fieldContent = item[column.fieldName as keyof IItems] as string;
  switch (column.key) {
    case "Status":
      return <span>{iconDisplayer(fieldContent, item)}</span>;

    case "Services":
      return (
        <span>
          <a className="service-name">{fieldContent}</a>
        </span>
      );

    default:
      return <span>{fieldContent}</span>;
  }
};
const iconDisplayer = (fieldContent: string, item: IItems): React.ReactNode => {
  if (item.messages.length < 1) {
    return (
      <>
        <i className="ms-Icon ms-Icon--SkypeCircleCheck" aria-hidden="true"></i>
      </>
    );
  } else if (fieldContent === "PostIncidentReviewPublished") {
    return (
      <span>
        <i className="ms-Icon ms-Icon--InfoSolid" aria-hidden="true"></i>
      </span>
    );
  } else {
    return (
      <span>
        <i className="ms-Icon ms-Icon--IncidentTriangle" aria-hidden="true"></i>
      </span>
    );
  }
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
