import axios from "axios";

import { IStatusRequest } from "./models";
import { appInitialization, initialize } from "@microsoft/teams-js";
import { globalConfig } from "./retry.config";

export const fetchAzurePage = async (data:IStatusRequest) => {
    const res = await axios.post("/GetAzurePages", data, globalConfig);
    const { succeeded, page } = res.data;
    
    return succeeded ? page : "A connection error occurred";
}