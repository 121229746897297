
import { useEffect } from "react";
import styles from "./DemoApp.module.css";
import ReactGa from "react-ga";
import {DemoBanner} from "./DemoBanner/DemoBanner";
import {  NavLink, Outlet,  useLocation } from "react-router-dom";

export default function DemoApp() {
  const location = useLocation()
  useEffect(() => {
    const host = window.location.hostname;
    if (host != "localhost") {
      ReactGa.initialize("UA-207888888-1");
      ReactGa.pageview("/demo");
    }
  }, []);
  useEffect(() => {
  }, [location.pathname])

  return (
    <>
      <nav className={styles.nav}>
        <div className={styles.left}>
          <img src="/logo.png" alt="logo" />
          <div>
            <h2>Cloud Hub</h2>
          </div>
          <NavLink className="demoNavLink" to="/demo" >Message Center</NavLink>
          <NavLink className="demoNavLink" to="/demo/incident" >Incident Center</NavLink>
          <NavLink className="demoNavLink" to="/demo/status"  >Azure Status</NavLink>
          <NavLink className="demoNavLink" to="/demo/history" >Azure History</NavLink>      
          <NavLink className="demoNavLink" to="/demo/history" >Azure Roadmap</NavLink>      
          <NavLink className="demoNavLink" to="/demo/history" >Azure News</NavLink>      
        </div>

        <div className={styles.right}>
          <img src="/reload.png" alt="refresh" />
          <img src="/Webpage.png" alt="webpage" />
        </div>
      </nav>
      <DemoBanner />
      <main>
        <Outlet />
      </main>
    </>
  );
}


