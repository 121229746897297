import { IHistoryItem } from "../models/models";
import { StatusIcon } from "./StatusIconComponent";

interface IHistoryListItemProps {
    item: IHistoryItem;
    handleClick: (item: IHistoryItem) => void;
    isOpen: boolean;
    horizontal?: boolean;
    children?: React.ReactNode;

  }
  export const HistoryListItem = ({ item, handleClick, isOpen, horizontal, children }: IHistoryListItemProps) => {
   
    return <div className="flex flex-col w-full py-3 mx-auto mb-3 rounded shadow cursor-pointer hover:bg-sky-100"  >
              <div className="flex justify-between px-4 " onClick={() => handleClick(item)}>
                <div className="flex items-center text-sky-700"> <StatusIcon item={item}/> <span className="ml-2 text-lg font-semibold md:text-xl">{item.service}</span></div>         
                <ChevronIconComponent isOpen={isOpen} horizontal={horizontal} />
              </div>
              {children}
            </div>
  }
  const ChevronIconComponent = ({ isOpen, horizontal }: { isOpen: boolean, horizontal?: boolean }) => {
    const icon = horizontal ? (isOpen ? "ChevronLeft" : "ChevronRight") : (isOpen ? "ChevronUp" : "ChevronDown");
    return <i className={`ms-Icon ms-Icon--${icon} flex items-center text-sky-700`} aria-hidden="true"></i>
  }
  