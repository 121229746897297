import { useQuery } from "react-query";
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Dropdown, IconButton, IDropdownOption, SearchBox } from "@fluentui/react";
import Header from "../../commonComponents/Header";
import { useEffect, useState } from "react";
import { CardComponent } from "../../commonComponents/CardComponent";
import { getDaysArr } from "../../helpers/helpers";
import { IAzureRoadmap } from "../../models/models";
import DownloadButton from "../../commonComponents/DownloadButton";
import { CloudHubDivider } from "../../commonComponents/Divider";
import { INewsRequest } from "../../api/models";
import { fetchRoadmap } from "../../api/roadmap.service";
import { useTeamsContext } from "../../context/teamsContext";
import { LoadingComponent } from "../../commonComponents/LoadingComponent";
import { app } from "@microsoft/teams-js";




export const RoadmapIndex = () => {
    const {getTenantId} = useTeamsContext()
    const [selectedDay, setSelectedDay] = useState<IDropdownOption>({key: 7, text: "7 Days"})
    const [filterText, setFilterText] = useState<string>("")
    const [parent] = useAutoAnimate()
    
    const params:INewsRequest = {
        days: selectedDay.key as number,
        TenantId: getTenantId && getTenantId(),
    }

    const {isLoading, data, isFetching} = useQuery<IAzureRoadmap[]>(['Roadmap', params], () => fetchRoadmap(params));
    const [filteredItems, setFilteredItems] = useState<IAzureRoadmap[]>([])
    const daysArr:IDropdownOption[] = getDaysArr()
    const handleDayChange = (option:IDropdownOption) => {
        setSelectedDay(option)        
    }
    const filterItems = () => {
        setFilteredItems(getFilteredItems())
    };
    const getFilteredItems = () => {
        if (!data) return [];
       return data.filter((item) => {
            if (filterText === "") return true;
            return filterHelper(item.title) || filterHelper(item.category) || filterHelper(item.description) 
        })
    }
    const filterHelper = (text: string) => {
        return text.toLowerCase().includes(filterText.toLowerCase())
    }
    
    const handleKeyWordFilterChange = (text:string) => {
        setFilterText(text)
    }
    const handleSubmit = () => {
        setFilterText(filterText)
    }
    const handleReset = () => {
        setSelectedDay({key: 7, text: "7 days"})
        setFilterText("")
    }
    useEffect(() => {
        filterItems()
    }, [data, filterText])



    if(!isLoading)  app.notifySuccess();
  return (
    <div className="relative">
        <Header title="Azure Roadmap" icon="TestPlan"/>
        
        <div className="flex justify-between max-w-screen-lg px-4 m-auto">
            <div className="">
                <Dropdown
                    options={daysArr}
                    onChange={(e, option) => handleDayChange(option!)}
                    label="Days"
                    selectedKey={selectedDay.key}
                />
            </div>
            <div className="flex items-end">
                <div className="ml-2">
                    <label className="py-1 pl-0 m-0 text-sm text-semibold">Keyword</label>
                    <SearchBox
                        placeholder="Search... "
                        onChange={(e, text) => handleKeyWordFilterChange(text ?? "")}
                        value={filterText}
                        className="border-sky-700 hover:border-sky-900 focus:outline-nonefocus:border-sky-900 hover:bg-sky-50 focus:bg-sky-50 "
                        // onSearch={handleSubmit}
                        onClear={() => handleKeyWordFilterChange("")}
                    />
                </div>
                <div className="flex items-end">
                    {/* <BasicButton handleClick={handleSubmit}>Search</BasicButton> */}
                    <IconButton
                        iconProps={{ iconName: "ClearFilter" }}
                        onClick={handleReset}
                        className="ml-2 font-semibold text-white transition duration-100 ease-in-out rounded bg-sky-700 hover:bg-sky-900"
                        title="Reset all filters"
                        ariaLabel="Reset all filters"
                    />
                    <DownloadButton
                        csvData={filteredItems}
                        csvFilename="AzureRoadmap.csv"
                    />
                </div>
            </div>
        </div>
        <CloudHubDivider count={filteredItems.length} />
        <div className="flex flex-col w-full max-w-screen-lg px-4 py-2 m-auto " ref={parent}>
        {
        filteredItems.length > 0 && !isFetching ? filteredItems.map(Roadmap => <CardComponent key={Roadmap.id} data={Roadmap} buttonText="Open on Azure"/> ) : <p className="my-12 text-xl text-center text-sky-700">No results found</p> }
        </div>
    </div>
  )
}


