import { Icon } from "@fluentui/react";
import { compareHelper } from "../helpers/helpers";

export const ServiceIconComponent = ({serviceName}:{serviceName: string}) => {
  return (
    <div>
      <ServiceIconElement serviceName={serviceName} />
      <span className="font-semibold text-sky-700" title={serviceName}>{serviceName}</span>
    </div>
  )
}

const ServiceIconElement = ({serviceName}:{serviceName: string}) => {      
            let color = "#009ca4"
            let iconName = "Unknown"
            if (
              compareHelper(serviceName,"Exchange Online") ||
              compareHelper(serviceName,"Exchange Online Protection")
            ) {
              return (
                  <Icon iconName="ExchangeLogo"
                    className={"ms-Icon root-32 css-685 iconStyles-856"}
                    aria-label="Exchange Online"
                  />
              );
            } else if (compareHelper(serviceName,"OneDrive for Business")) {
              return (
                
                  <Icon iconName="OneDrive"
                    className={"ms-Icon root-32 css-392 iconStyles-856"}
                    aria-label="OneDrive for Business"
                  />
               
              );
            } else if (
              compareHelper(serviceName,"SharePoint Online") ||
              compareHelper(serviceName,"SharePoint Syntex")
            ) {
              return (
                
                  <Icon iconName="SharepointLogo"
                    className={"ms-Icon root-32 css-685 iconStyles-848"}
                    aria-label="SharePoint Online"
                  />
               
              );
            } else if (
              compareHelper(serviceName,"Dynamics 365")||
              compareHelper(serviceName,"Dynamics 365 Business Central")||
              compareHelper(serviceName,"Dynamics 365 Apps")
              ) {
              return (
                
                  <Icon iconName="Dynamics365Logo"
                    className={"ms-Icon root-32 css-683 iconStyles-860"}
                    aria-label="Dynamics 365"
                  />
               
              );
            } else if (compareHelper(serviceName,"Microsoft 365 suite")) {
              return (
                
                  <Icon iconName="AdminALogo32"
                    className={"ms-Icon root-32 css-681 iconStyles-852"}
                    aria-label="Microsoft 365 suite"
                  />
               
              );
            } else if (compareHelper(serviceName,"Yammer Enterprise")) {
              return (
                
                  <Icon iconName="YammerLogo"
                    className={"ms-Icon root-32 css-539 iconStyles-862"}
                    aria-label="Yammer"
                  />
               
              );
            } else if (compareHelper(serviceName,"Microsoft Teams")) {
              return (
                
                  <Icon iconName="TeamsLogo"
                    className={"ms-Icon root-32 css-685 iconStyles-858"}
                    aria-label="Microsoft Teams"
                  />
               
              );
            } else if (
              compareHelper(serviceName,"Microsoft Power Automate in Microsoft 365")||
              compareHelper(serviceName,"Microsoft Power Automate")) {
              return (
                
                  <Icon iconName="MicrosoftFlowLogo"
                    className="ms-Icon root-32 css-681 iconStyles-868"
                    aria-label="Microsoft Power Automate in Microsoft 365"
                  />
               
              );
            } else if (compareHelper(serviceName,"Skype for Business")) {
              return (
                
                  <Icon iconName="SkypeLogo"
                    className={"ms-Icon root-32 css-678 iconStyles-856"}
                    aria-label="Skype for Business"
                  />
                  
               
              );
            } else if (
              compareHelper(serviceName,"Identity Service")||
              compareHelper(serviceName,"Microsoft Defender for Endpoint")||
              compareHelper(serviceName,"Microsoft Defender") ||
              compareHelper(serviceName,"Microsoft Defender for Cloud Apps") ||
              compareHelper(serviceName,"Microsoft Intune")||
              compareHelper(serviceName,"Microsoft 365 Defender") ||
              compareHelper(serviceName,"Mobile Device Management for Office 365") ||
              compareHelper(serviceName,"Microsoft Viva")
            ) {
              return (
                
                  <Icon iconName="Product"
                    className={"ms-Icon root-32 css-539 iconStyles-852"}
                    aria-label="Identity Service,Microsoft Defender for Endpoint"
                  />
                  
               
              );
            } else if (compareHelper(serviceName,"Planner")) {
              return (
                
                  <Icon iconName="PlannerLogo"
                    className={"ms-Icon root-32 css-683 iconStyles-853"}
                    aria-label="Planner"
                  />
                  
                  
               
              );
            } else if (compareHelper(serviceName,"Microsoft Forms")) {
              return (
                
                  <Icon iconName="OfficeFormsLogo"
                    className="ms-Icon root-32 css-681 iconStyles-859"
                    aria-label="Microsoft Forms"
                  />
                 
                  
               
              );
            } else if (
              compareHelper(serviceName,"Office for the web")||
              compareHelper(serviceName,"Microsoft 365 Apps")||
              compareHelper(serviceName,"Microsoft 365 for the web")||
              compareHelper(serviceName,"Finance and Operations Apps")            ) {
              return (
                
                  <Icon iconName="OfficeLogo"
                    className={"ms-Icon root-32 css-678 iconStyles-854"}
                    aria-label={serviceName}
                  />
                    
                  
               
              );
            } else if (
              compareHelper(serviceName,"Power Apps in Microsoft 365")||
              compareHelper(serviceName,"Power Apps")) {
              return (
                
                  <Icon iconName="PowerAppsLogo"
                    className={"ms-Icon root-32 css-843 iconStyles-869"}
                    aria-label="Power Apps in Microsoft 365"
                  />
                    
                  
               
              );
            } else if (compareHelper(serviceName,"Power BI")){
              return (
                
                  <Icon iconName="PowerAppsLogo"
                    className="ms-Icon ms-Icon--PowerBILogo"
                    aria-label="Power BI in Microsoft 365"
                  />
               
              );
            } else if (compareHelper(serviceName,"Microsoft Bookings")) {
              return (
                
                  <Icon iconName="BookingsLogo"
                    className="ms-Icon ms-Icon--BookingsLogo iconStyles-1022"
                    aria-label="Microsoft Bookings"
                  />
              );
            } else if (compareHelper(serviceName,"Stream")|| compareHelper(serviceName,"Microsoft Stream"))  {
              return (
                
                  <Icon iconName="StreamLogo"
                    className="ms-Icon ms-Icon--StreamLogo iconStyles-1140"
                    aria-label="Microsoft Stream"
                  />
               
              );
            } else if (compareHelper(serviceName,"Project for the web"))  {
              return (
                
                  <Icon iconName="ProjectLogo16"
                    className="ms-Icon ms-Icon--ProjectLogo16 iconStyles-1023"
                    aria-label="Project for the web"
                  />
               
              );
            } else if (compareHelper(serviceName,"Windows"))  {
              const color = "rgb(0, 120, 215)"
              
              return (        
                //TODO condition only for colors and iconnames to make one ICOn component       
                  <Icon iconName="WindowsLogo"
                    style={{color: color, fontSize: 16, marginRight:4, verticalAlign: "sub"}}
                    aria-label={serviceName}
                  />
               
              );
            }
            return <Icon iconName={iconName}  aria-label={serviceName} color={color} style={{fontSize: 16, marginRight:4, verticalAlign: "sub"}}/>;
          
    
}