import { useAutoAnimate } from "@formkit/auto-animate/react"
import HTMLReactParser from "html-react-parser"
import { useState } from "react"
import { formatDate, handleOpenLink } from "../helpers/helpers"
import { IAzureRoadmap, IAzureNews, IAzureHistoryItem } from "../models/models"
import { Icon } from "office-ui-fabric-react"

interface CardProps {
  data:IAzureRoadmap | IAzureNews,
  buttonText?: string 
}
export const CardComponent = ({data, buttonText}:CardProps) => {
    const lastUpdated = 'lastUpdatedTime' in data ? data.lastUpdatedTime : null
    return (
        <div className="flex flex-col items-start content-center w-full p-4 mb-4 rounded-md shadow-lg">
          <h2 className="mb-2 text-lg font-semibold md:text-xl text-sky-700">{data.title}</h2>
          <p className="mb-2">{data.category}</p>
          <p className="mb-1">Published: {formatDate(data.publisDate)}</p>
          {lastUpdated ? <p className="mb-1">Last Updated: {formatDate(lastUpdated)}</p> : null}
          <p className="mb-4">{HTMLReactParser(data.description)}</p>
          <div className="">
            <a href={data.links} target="_blank" rel="noreferrer" onClick={handleOpenLink} className="px-4 py-2 font-semibold text-white no-underline transition duration-100 ease-in-out rounded bg-sky-700 hover:bg-sky-700">{buttonText || 'Continue reading'}</a>
          </div>
        </div>        
    )
}
interface HistoryCardProps {
  data: IAzureHistoryItem
}
  
export const HistoryCardComponent = ({data}:HistoryCardProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [parent] = useAutoAnimate()
    const handleToggle = () => {
      setIsOpen(!isOpen)
    }
  return (
      <div className="relative flex flex-col items-start content-center w-full p-4 mb-4 rounded-md shadow-lg cursor-pointer" onClick={handleToggle} ref={parent}>
        <h2 className="mb-2 text-lg font-semibold md:text-xl text-sky-700">{data.title}</h2>
        <p className="mb-1">Published: {formatDate(data.date)}</p>
        <Icon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} className="absolute mb-2 right-4 top-4 text-sky-700" />
       {isOpen ? <p className="">{HTMLReactParser(data.description)}</p> : null }
        
      </div>        
  )

}