import { useEffect, useRef, useState } from "react"
import { useTeamsContext } from "../context/teamsContext"
import { Icon } from "office-ui-fabric-react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import styles from "./consentHeader.module.css"


export const ConsentHeader = () => {
    const [showConsent, setShowConsent] = useState<boolean>(false)
    const [showConsentLink, setShowConsentLink] = useState<boolean>(false)
    const {loggedIn, needConsent} = useTeamsContext()
    const [parent] = useAutoAnimate()
    const handleCloseHeader = () => {
        setShowConsent(false)
    }
    const handleCopyLink = () => {
        if(!loggedIn) return
        navigator.clipboard.writeText(loggedIn?.approveLink)
        .then(() => alert("Link copied to clipboard"))
        .catch((err) => {
            setShowConsentLink(true)
        });
    }
    useEffect(() => {
        if(needConsent) setTimeout(() => setShowConsent(true), 1000);
    }, [needConsent])
    return (
      <div> 
        { loggedIn?.approved && showConsent ? <>
            <div className={`fixed z-50 flex items-center py-3 w-full text-white bg-orange-500 ${loggedIn?.approved && showConsent ?  `opacity-100 ${styles.heightOnShow}` :  `${styles.heightOnHide} -translate-y-full opacity-0`}`}>
                <div className="w-[60px] px-2  flex items-center justify-center">
                    <Icon iconName="Warning" className="text-4xl cursor-default"   />
                </div>
                <div ref={parent} className="px-2">
                    <p className="text-lg font-semibold">Attention all users!</p>
                    <p className="mb-1">Cloud Hub has updated it's permissions for extended functionality and increased security. As part of these updates, we need you to re-consent to the app. If you are not an admin, please forward this link to your admin. Thank you for your cooperation!</p>
                    {showConsentLink ? <p className="py-1 font-semibold">{loggedIn?.approveLink}</p> : <button onClick={handleCopyLink} className="px-2 py-1 font-semibold text-orange-500 bg-white rounded">Show Link</button> }
                </div> 
                <button className="absolute top-3 right-2" onClick={handleCloseHeader} aria-label="close button">
                    <Icon iconName="ChromeClose" className=""  />
                </button>
            </div>
        </>
        : null}
    </div> 
    )
}