import { IButtonStyles, IconButton } from "@fluentui/react";
import { IMessage } from "../../../models/models";
import { IColumn  } from "../utils/interfaces";
const markItemAsRead = (items: IMessage[], item: IMessage, setState: any) => {
  const newArray = items.map((obj) => {
    if (obj.id === item.id) {
      obj.isRead = true;
      return obj;
    } else {
      return obj;
    }
  });
  setState(newArray);
};
const markItemAsFavorite = (items: IMessage[], item: IMessage, setState: any) => {
  const newArray = items.map((obj) => {
    if (obj.id === item.id) {
      obj.isFavorite = !obj.isFavorite;
      return obj;
    } else {
      return obj;
    }
  });

  setState(newArray);
};
const markItemAsArchived = (items: IMessage[], item: IMessage, setState: any) => {
  const newArray = items.map((obj) => {
    if (obj.id === item.id) {
      obj.isArchived = !obj.isArchived;

      return obj;
    } else {
      return obj;
    }
  });
  setState(newArray);
};

export default function RenderColumns(
  item: IMessage,
  column: IColumn,
  isPopUpOpen: boolean,
  setIsPopUpOpen: any,
  setSelectedItem: any,
  filteredItems: any[] | IMessage[],
  setFilteredItems: any,
  setIsSharePopUpOpen: any,
  setSelectedShareItem: any
) {
  const handlePopUpClick = (e: any, item: IMessage): void => {
    setSelectedItem(item);
    setIsPopUpOpen(!isPopUpOpen);
    markItemAsRead(filteredItems, item, setFilteredItems);
  };
  const handleIsFavorite = (e: any, item: IMessage) => {
    markItemAsFavorite(filteredItems, item, setFilteredItems);
  };
  const handleIsArchived = (e: any, item: IMessage) => {
    markItemAsArchived(filteredItems, item, setFilteredItems);
  };
  const handleShare = (item: IMessage) => {
    setSelectedShareItem(item);
    setIsSharePopUpOpen(true);
  };

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { marginBottom: "1px", height: "17px" },
  };

  switch (column.name) {
    case "isFavorite":
      return (
        <>
          <i
            className={
              item.isFavorite
                ? "ms-Icon  ms-Icon--FavoriteStarFill"
                : "ms-Icon  ms-Icon--FavoriteStar"
            }
            aria-hidden="true"
            onClick={(e) => handleIsFavorite(e, item)}
          ></i>
        </>
      );
    case "Message title":
      return (
        <>
          <span>
            <a
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                handlePopUpClick(e, item)
              }
              style={{ fontWeight: item.isRead ? "normal" : "bold" }}
            >
              {item.messageTitle}
            </a>
          </span>
        </>
      );
    case "more":
      return (
        <IconButton
          menuIconProps={{ iconName: "MoreVertical" }}
          role="button"
          aria-haspopup
          styles={iconButtonStyles}
          menuProps={{
            items: [
              {
                key: "share",
                text: "Share",
                iconProps: { iconName: "Share" },
                onClick: (e) => handleShare(item),
              },
              {
                key: "archive",
                text: item.isArchived ? "Move to Inbox" : "Archive",
                iconProps: { iconName: item.isArchived ? "Inbox" : "Archive" },
                onClick: (e) => handleIsArchived(e, item),
              },
            ],
          }}
        />
      );
    case "Service":
      return (
        <span>
          {item.service.map((serviceName: string) => {
            if (
              serviceName === "Exchange Online" ||
              serviceName === "Exchange Online Protection"
            ) {
              return (
                <>
                  <i
                    data-icon-name="ExchangeLogo"
                    className={"ms-Icon root-32 css-685 iconStyles-856"}
                    aria-label="Exchange Online"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "OneDrive for Business") {
              return (
                <>
                  <i
                    data-icon-name="OneDrive"
                    className={"ms-Icon root-32 css-392 iconStyles-856"}
                    aria-label="OneDrive for Business"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (
              serviceName === "SharePoint Online" ||
              serviceName === "SharePoint Syntex"
            ) {
              return (
                <>
                  <i
                    data-icon-name="SharepointLogo"
                    className={"ms-Icon root-32 css-685 iconStyles-848"}
                    aria-label="SharePoint Online"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (
              serviceName === "Dynamics 365" ||
              serviceName === "Dynamics 365 Business Central" ||
              serviceName === "Dynamics 365 Apps"
            ) {
              return (
                <>
                  <i
                    data-icon-name="Dynamics365Logo"
                    className={"ms-Icon root-32 css-683 iconStyles-860"}
                    aria-label="Dynamics 365"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Microsoft 365 suite") {
              return (
                <>
                  <i
                    data-icon-name="AdminALogo32"
                    className={"ms-Icon root-32 css-681 iconStyles-852"}
                    aria-label="Microsoft 365 suite"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Yammer Enterprise") {
              return (
                <>
                  <i
                    data-icon-name="YammerLogo"
                    className={"ms-Icon root-32 css-539 iconStyles-862"}
                    aria-label="Yammer"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Microsoft Teams") {
              return (
                <>
                  <i
                    data-icon-name="TeamsLogo"
                    className={"ms-Icon root-32 css-685 iconStyles-858"}
                    aria-label="Microsoft Teams"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (
              serviceName === "Microsoft Power Automate in Microsoft 365" ||
              serviceName === "Microsoft Power Automate"
            ) {
              return (
                <>
                  <i
                    data-icon-name="MicrosoftFlowLogo"
                    className="ms-Icon root-32 css-681 iconStyles-868"
                    aria-label="Microsoft Power Automate in Microsoft 365"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Skype for Business") {
              return (
                <>
                  <i
                    data-icon-name="SkypeLogo"
                    className={"ms-Icon root-32 css-678 iconStyles-856"}
                    aria-label="Skype for Business"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (
              serviceName === "Identity Service" ||
              serviceName === "Microsoft Defender for Endpoint"
            ) {
              return (
                <>
                  <i
                    data-icon-name="Product"
                    className={"ms-Icon root-32 css-539 iconStyles-852"}
                    aria-label="Identity Service,Microsoft Defender for Endpoint"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Planner") {
              return (
                <>
                  <i
                    data-icon-name="PlannerLogo"
                    className={"ms-Icon root-32 css-683 iconStyles-853"}
                    aria-label="Planner"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Microsoft Forms") {
              return (
                <>
                  <i
                    data-icon-name="OfficeFormsLogo"
                    className="ms-Icon root-32 css-681 iconStyles-859"
                    aria-label="Microsoft Forms"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (
              serviceName === "Office for the web" ||
              serviceName === "Microsoft 365 Apps" ||
              serviceName === "Microsoft Intune" ||
              serviceName === "Finance and Operations Apps"
            ) {
              return (
                <>
                  <i
                    data-icon-name="OfficeLogo"
                    className={"ms-Icon root-32 css-678 iconStyles-854"}
                    aria-label="Office for the web"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (
              serviceName === "Power Apps in Microsoft 365" ||
              serviceName === "Power Apps"
            ) {
              return (
                <>
                  <i
                    data-icon-name="PowerAppsLogo"
                    className={"ms-Icon root-32 css-843 iconStyles-869"}
                    aria-label="Power Apps in Microsoft 365"
                  >
                    
                  </i>
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Power BI") {
              return (
                <>
                  <i
                    data-icon-name="PowerAppsLogo"
                    className="ms-Icon ms-Icon--PowerBILogo"
                  />
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else if (serviceName === "Microsoft 365 Defender") {
              return (
                <>
                  <i
                    data-icon-name="PowerAppsLogo"
                    className="ms-Icon ms-Icon--DefenderApp"
                  />
                  <span className="icon-label">{serviceName}</span>
                </>
              );
            } else {
              return <span className="icon-label">{serviceName}</span>;
            }
          })}
        </span>
      );
    case "Created on":
      return <><span>{item.startTime}</span></>
    case "Last updated":
      return (
        <>
          <span>{item.lastUpdatedTime}</span>
        </>
      );

    case "Message ID":
      return (
        <>
          <span>{item.id}</span>
        </>
      );
    case "Category":
      return (
        <>
          <span>{item.category}</span>
        </>
      );
    case "Severity":
      return (
        <>
          <span>{item.severity}</span>
        </>
      );
  }
}
