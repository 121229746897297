
interface HeaderProps {
  title: string;
  icon: string;
  subtitle?: string;
}

export default function Header({
  title,
  icon,
  subtitle = undefined,
}: HeaderProps) {
  return (
    <div className="bg-sky-700 flex items-center h-[100px] pl-3">
      <i className={`ms-Icon ms-Icon--${icon} text-sky-50 text-4xl mr-2`} aria-hidden="true"></i>
      <div className="text-sky-50">
        <h1 className="text-4xl text-sky-50">{title}</h1> {subtitle ? <span>{subtitle}</span> : null}
      </div>
    </div>
  );
}
