import "./App.css";

// import styles from "./Login.module.css";
import { useEffect } from "react";
import { handleOpenLink } from "../helpers/helpers";
import { BasicButton } from "../commonComponents/Buttons";
import Header from "../commonComponents/Header";
import { IIsLoggedIn } from "../models/models";
import { app } from "@microsoft/teams-js";



const REFRESH_INTERVAL = 30000;
const DEMO_URL = process.env.REACT_APP_WEB_URL + "/demo";
export const POPUP_OPTIONS = "width=500,height=600,top=300,left=500";

export interface LoginProps {
  loggedIn: IIsLoggedIn;
  initCall: () => void
}

export default function Login({
  loggedIn,
  initCall
}: LoginProps) {
  const handleRefresh = () => {
    initCall()
  };

  const handleOpenPopup = () => {
    const win = window.open(
      loggedIn.approveLink + "&redirect_uri=" + window.location.origin + "/consent",
      "myWindow",
      POPUP_OPTIONS
    );
    if (win) {
      win.onunload = () => {
        handleRefresh();
      };
    }
  };
  const handleClick = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to open this link?")) {
      window.open(DEMO_URL, "_blank");
    }
  };
  useEffect(() => {
    app.notifySuccess()
    const interval = setInterval(() => {
      // Call your function here
      handleRefresh();
    }, REFRESH_INTERVAL);
    return () => {
      clearInterval(interval);
    }
  }, []);

  
  return (
    <div>
      <Header title="Welcome to Cloud Hub" icon="Cloud"/>

        {loggedIn.loadingStatus === 0 ? (
          <div className="flex flex-col items-center justify-center">
            <h2 className="my-8 text-4xl font-semibold text-sky-700">Almost done</h2>
            <p className="p-4 bg-sky-700/[0.3] text-sky-700 max-w-screen-sm mx-auto rounded shadow-xl">
              If you are the <b>tenant's global administrator</b> just click the
              get started button and approve the required app permissions.{" "}
              <a
                href="https://learn.microsoft.com/en-us/microsoft-365-app-certification/docs/certification"
                target="_blank"
                rel="noreferrer noopener"
                className="px-1 no-underline transition duration-100 ease-in-out border-b border-transparent text-sky-700 text-semibold hover:border-sky-500 hover:no-underline hover:text-500"
                onClick={handleOpenLink}
              >
                Click here to learn more
              </a>
              <br />
              After that process, you should be able to use the app within a few
              minutes. {/* Hit the refresh button after consenting to see if the
              download of the tenant data already started. */}
            </p>
            <div>
              <BasicButton handleClick={handleOpenPopup} classes="my-8 text-xl shadow-xl">Get Started</BasicButton>              
            </div>
            <div>
              <BasicButton handleClick={handleRefresh} classes="bg-blue-500 mr-2"><i className="ms-Icon ms-Icon--Refresh"></i> Refresh</BasicButton>
              <BasicButton handleClick={handleClick} classes="bg-blue-500"><i className="ms-Icon ms-Icon--OpenInNewTab"></i> View Demo</BasicButton>
            </div>

            <img src="/loading.svg"  alt="loading" className="w-[50%] my-4 max-w-[300px]" />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <h2 className="my-8 text-4xl font-semibold text-sky-700">Syncing your Data</h2>
            <p className="p-4 bg-sky-700/[0.3] text-sky-700 max-w-screen-sm mx-auto rounded shadow-xl">
              The app registration was successful! We are processing the required data.
              <br /> The process can take up to more minutes but we check the status continuously.
              <br /> After the process is finished the app will let you in automatically.
            </p>
            <div >
              <BasicButton handleClick={handleRefresh} classes="my-8">Refresh</BasicButton>
            </div>
            <img src="/download.svg"  alt="download" className="w-[50%] my-4"/>
          </div>
        )}
    </div>
  );
}
