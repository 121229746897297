import {
  Modal,
  PrimaryButton,
  ProgressIndicator,
} from "@fluentui/react";
import { useState, useLayoutEffect } from "react";
import { ISecondLevelmessages } from "../utils/interfaces";
import "./Popup.css";

const modalDesktopStyle = {
  scrollableContent: {
    width: "80vw",
    height: "70vh",
    padding: "40px",
  },
  main: {
    width: "calc(80vw+40px)",
    height: "calc(70vw+40px)",
  },
};
const modalMobileStyle = {};

interface ICardProps {
  isPopUpOpen: boolean;
  setIsPopUpOpen: any;
  messages: ISecondLevelmessages[] | undefined;
  setMessages: any;
  title: string;
  statusDisplayName: string;
}
export default function Popup({
  isPopUpOpen,
  setIsPopUpOpen,
  messages,
  setMessages,
  title,
  statusDisplayName,
}: ICardProps) {
  const [windowWidth] = useWindowSize();
  const handleClose = () => {
    setMessages(undefined);
    setIsPopUpOpen(false);
  };

  return (
    <div>
      <Modal
        allowTouchBodyScroll={false}
        isOpen={isPopUpOpen}
        isBlocking={false}
        onDismiss={handleClose}
        styles={windowWidth > 1000 ? modalDesktopStyle : modalMobileStyle}
      >
        <div className="popup-container">
          <header>
            <h1>{title}</h1>
            <a className="close" onClick={handleClose}>
              ×
            </a>
          </header>
          {messages ? (
            messages.map((message: ISecondLevelmessages, index: number) => {
              return (
                <div key={index} className="messages">
                  <h2>
                    {statusDisplayName} - {message.publishTime}
                  </h2>
                  {newlineText(message.messageText)}

                  <hr />
                </div>
              );
            })
          ) : (
            <ProgressIndicator />
          )}
        </div>
        <PrimaryButton
          className="closebutton"
          onClick={handleClose}
          text="Close"
          iconProps={{
            iconName: "ChromeClose",
            style: {
              fontSize: "14px",
            },
          }}
        />
      </Modal>
    </div>
  );
}

function newlineText(text: string) {
  const newText = text
    .split("\n")
    .map((str, index) => <p key={index}>{str}</p>);

  return newText;
}
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
