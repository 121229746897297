import {
  CompactPeoplePicker,
  IBasePickerSuggestionsProps,
  IPersonaProps,
  Stack,
} from "@fluentui/react";
import { GetSearchPeople } from "../utils/Helper";
import styles from "./PeoplePicker.module.css";

interface PeoplePickerProps {
  selectedPeople: [] | Person[];
  setSelectedPeople: any;
}
export interface Person {
  text: string;
  key: string;
}

export default function PeoplePicker({
  selectedPeople,
  setSelectedPeople,
}: PeoplePickerProps) {
  const onFilterChanged = (
    filterText: string
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return GetSearchPeople(filterText);
    } else {
      return [];
    }
  };
  const handleChange = (people: Person[]) => {
    setSelectedPeople(people);
  };

  return (
    <div className={styles.peoplePicker}>
      <Stack horizontal verticalAlign="center">
        <i className="ms-Icon ms-Icon--Mail" />
        <span> People you want to write a Mail</span>
      </Stack>

      <CompactPeoplePicker
        onResolveSuggestions={onFilterChanged}
        pickerSuggestionsProps={suggestionProps}
        onChange={(people: any) => handleChange(people)}
      />
    </div>
  );
}
const suggestionProps: IBasePickerSuggestionsProps = {
  noResultsFoundText: "No results found",
  loadingText: "Loading",
};
