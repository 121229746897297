import { IButtonStyles, IconButton } from "@fluentui/react";
import * as React from "react";
import { ServiceIconComponent } from "../../../commonComponents/ServiceIcon";
import { IMessage } from "../../../models/models";
import { IColumn, } from "../utils/interfaces";
import { useTeamsContext } from "../../../context/teamsContext";


export function RenderColumns(
  item: IMessage,
  column: IColumn,
  handleSelectItem: (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>, item: IMessage) => void,
  handleShareItem: (item: IMessage) => void,
  handleSetArchiveItem: (e: React.MouseEvent<HTMLElement, MouseEvent>  | React.KeyboardEvent<HTMLElement> | undefined, item: IMessage) => Promise<void>,
  handleSetFavoriteItem: (e: React.MouseEvent<HTMLElement, MouseEvent>, item: IMessage) => void,
) {
  const { needConsent } = useTeamsContext();
  let itemsOnlyArchive = [      
      {
        key: "archive",
        text: item.isArchived ? "Move to Inbox" : "Archive",
        iconProps: { iconName: item.isArchived ? "Inbox" : "Archive" },
        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => handleSetArchiveItem(e, item),
      }
  ]
  let items = [ 
    {
      key: "share",
      text: "Share",
      iconProps: { iconName: "Share" },
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => handleShareItem(item),      
    },     
    {
      key: "archive",
      text: item.isArchived ? "Move to Inbox" : "Archive",
      iconProps: { iconName: item.isArchived ? "Inbox" : "Archive" },
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => handleSetArchiveItem(e, item),
    }
  ]
  

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { marginBottom: "1px", height: "17px" },
  };

  switch (column.name) {
    case "isFavorite":
      return (
        <>
          <i
            className={
              item.isFavorite
                ? "ms-Icon  ms-Icon--FavoriteStarFill"
                : "ms-Icon  ms-Icon--FavoriteStar"
            }
            aria-hidden="true"
            onClick={(e) => handleSetFavoriteItem(e, item)}
          ></i>
        </>
      );
    case "Message title":
      return (
          <span className="cursor-pointer">
            <p className="m-0 font-semibold" title={item.messageTitle}
              onClick={(e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) =>
                handleSelectItem(e, item)
              }              
            >
              {item.messageTitle}
            </p>
          </span>
      );
    case "more":
      return (
        <IconButton
          menuIconProps={{ iconName: "MoreVertical" }}
          role="button"
          aria-haspopup
          styles={iconButtonStyles}
          menuProps={{
            items: needConsent ? itemsOnlyArchive : items,
          }}
        />
      );
    case "Service":
      return (
        <span>
          {item.service.map((serviceName: string) => <ServiceIconComponent key={serviceName} serviceName={serviceName} /> )}
        </span>
      );

    case "End Time":
      return (
        <>
          <span>{item.endTime}</span>
        </>
      );
    case "Start Time":
      return (
        <>
          <span>{item.startTime}</span>
        </>
      );

    case "Message ID":
      return (
        <>
          <span>{item.id}</span>
        </>
      );
    case "Category":
      return (
        <>
          <span>{item.category}</span>
        </>
      );
    case "Severity":
      return (
        <>
          <span>{item.severity}</span>
        </>
      );
  }
}




