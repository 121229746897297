import { Callout, IconButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useState } from "react";
import { CSVLink } from "react-csv";

interface downloadButtonProps {
  csvData: {}[] | [];
  csvFilename: string;
}

export default function DownloadButton({
  csvData,
  csvFilename,
}: downloadButtonProps) {
  const [showSpinnerIcon, setShowSpinnerIcon] = useState<boolean>(false);
  const [calloutVisible, setCalloutVisible] = useState<boolean>(false);

  const startAnimation = () => {
    setShowSpinnerIcon(!showSpinnerIcon);
    setTimeout(() => setShowSpinnerIcon(false), 1000);
    setTimeout(() => setCalloutVisible(true), 1000);
  };

  const toggleCalloutVisible = () => {
    setCalloutVisible(false);
  };
  return (
    <div>
      {showSpinnerIcon ? (
        <div style={{ margin: "7px" }}>
          <Spinner style={{ display: "inline" }} size={SpinnerSize.small} />
        </div>
      ) : (
        <>
          <CSVLink data={csvData} filename={csvFilename}>
            <IconButton
              iconProps={{ iconName: "Download" }}
              onClick={startAnimation}
              className="bg-sky-700 hover:bg-sky-900 text-white font-semibold rounded transition duration-100 ease-in-out ml-2"
              title="Download Result as Excel"
              aria-label="download Result as Excel"
            />
          </CSVLink>
          {calloutVisible ? (
            <Callout
              role="alertdialog"
              gapSpace={0}
              setInitialFocus
              target=".downloadButton"
              onDismiss={toggleCalloutVisible}
              style={{ padding: "10px 20px" }}
            >
              <h1 style={{ fontWeight: "lighter" }}>
                <i
                  className="ms-Icon ms-Icon--DownloadDocument"
                  aria-hidden="true"
                  style={{ marginRight: "10px" }}
                />
                File downloaded
              </h1>

              <p style={{ fontWeight: "lighter" }}>
                You can find your file in your local Download folder
              </p>
            </Callout>
          ) : null}
        </>
      )}
    </div>
  );
}
