import "./App.css";
import { Route, Routes } from "react-router-dom";
import { IncidentCenterIndex } from "./incidentsTab/IncidentCenterIndex";
import { useCallback, useEffect, useState } from "react";
import Login, { POPUP_OPTIONS } from "./Login";

import ConsentPage from "./consentPage/consentPage";
import DemoApp from "../Demo/DemoApp";
import AzureStatus from "./azureStatus/azureStatus";
import { AzureHistoryIndex } from "./azureHistory/azureHistory";
import MessageCenterDemo from "../Demo/messageCenterTab/components/MessageCenterDemo";
import IncidentCenterDemo from "../Demo/incidentsTab/components/IncidentCenterDemo";
import AzureStatusDemo from "../Demo/azureStatusDemo/azureStatusDemo";
import AzureHistoryDemo from "../Demo/azureHistoryDemo/azureHistoryDemo";
import { NewsIndex } from "./news/News";
import { RoadmapIndex } from "./roadmap/Roadmap";
import { IIsLoggedIn } from "../models/models";
import { useTeamsContext } from "../context/teamsContext";
import { fetchIsLoggedIn } from "../api/auth.service";
import { useAuth } from "../hooks/useAuth";
import { MessageCenterIndex } from "./messageCenterTab/MessageCenterIndex";
import { app } from "@microsoft/teams-js";
import ConsentPopup from "./consentPage/ConsentPopup";
import ClosePopup from "./consentPage/ClosePopup";

// import { useAutoAnimate } from "@formkit/auto-animate/react";
// import { Icon } from "office-ui-fabric-react";

export const App = () => {
  const [loggedIn, setLoggedIn] = useState<IIsLoggedIn>();
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { isTokenSet } = useAuth()

  const { context, handleSetContext, getBasicUserData, handleSetIsLoggedIn } = useTeamsContext();
  // const [parent] = useAutoAnimate()

  const handleIsloggedIn = async () => {
    if(!context) return 
    const data =  getBasicUserData && getBasicUserData()
    if(!data) return
    const response = await fetchIsLoggedIn(data)    
    setLoggedIn(response.data);
    handleSetIsLoggedIn && handleSetIsLoggedIn(response.data)
  }

  const initCall = async () => {
    setIsLoading(true)
    handleGetContext()
  }
  
  useEffect(() => {
    app.initialize().then(() => {
      initCall()
    })
  }, []);

  const handleGetContext = useCallback(() => {
    if(!context){
       app.getContext().then(context => handleSetContext && handleSetContext(context));
    }
   
  }, []);


  useEffect(() => {
    if (context && isTokenSet) {
      handleIsloggedIn()
    }
  }, [context, isTokenSet]);
 
  useEffect(() => {
    if(loggedIn){
      setIsLoading(false)      
    }
  }, [loggedIn])

  return (
    <div className="min-h-screen">   
    <Routes>
      <Route path="consent" element={<ConsentPage />} />
      <Route path="/demo" element={<DemoApp />}>   
        <Route  path="" element={<MessageCenterDemo />} />
        <Route  path="incident" element={<IncidentCenterDemo/>} />
        <Route  path="status" element={<AzureStatusDemo/>} />
        <Route  path="history" element={<AzureHistoryDemo/>} />
        <Route  path="roadmap" element={<div>Roadmap</div>} />
        <Route  path="news" element={<div>News</div>} />
      </Route>
       
     { !isLoading && loggedIn ? <>
      <Route path="incidentcenter"  
          element={loggedIn.approved ?
            <IncidentCenterIndex /> : 
            <Login loggedIn={loggedIn}  initCall={initCall} />
          } />
      
          <Route path="messagecenter" element={loggedIn.approved ?
            <MessageCenterIndex  /> :
            <Login loggedIn={loggedIn}  initCall={initCall}/>
          } />

          <Route path="azurestatus" element={loggedIn.approved ?
            <AzureStatus /> :
            <Login loggedIn={loggedIn}  initCall={initCall}/>
          } />
          <Route path="azurehistory" element={loggedIn.approved ?
            <AzureHistoryIndex /> :
            <Login loggedIn={loggedIn} initCall={initCall} />
          } />
          <Route path="azureroadmap" element={loggedIn.approved ?
           <RoadmapIndex /> :
            <Login loggedIn={loggedIn} initCall={initCall} />
          } />
          <Route path="azurenews" element={loggedIn.approved ?
            <NewsIndex /> :
            <Login loggedIn={loggedIn}  initCall={initCall}/>
          } />
           <Route path="auth-start" element={<ConsentPopup/>} />
           <Route path="auth-end" element={<ClosePopup/>}/>
        </>
        : null}
    </Routes> 
        
    </div>
  );
}
