import { IDropdownOption } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { fetchMessageModalData, getTeams, getTeamsChannels } from "../api/message.service.ts";
import { useTeamsContext } from "../context/teamsContext.tsx";
import { ISelectedItem } from "../models/models.ts";

export const useShareModalData = ({messageId}: {messageId: string}) => {
    const {getTenantId, getUserPrincipalName} = useTeamsContext()

    const messageParams = {
        messageId: messageId,
        TenantId: getTenantId && getTenantId() || "",
    }
    const { data: teams, isLoading: isLoadingTeams } = useQuery<IDropdownOption[]>(["teams"], () =>
        getTeams()
    );
    const { data: messageBody, isLoading: isLoadingMessage } = useQuery<ISelectedItem>(["message", messageParams], () =>
        fetchMessageModalData(messageParams)
    );
    const isLoading = isLoadingTeams  || isLoadingMessage;
    return { teams, messageBody, isLoading };
    }



export const useChannelsData = () => {
    const [teamId, setTeamId] = useState<string>("");
    const qc = useQueryClient()
    const { data: channels, isLoading: isLoadingChannels } = useQuery<IDropdownOption[]>(["channels", teamId], () => 
        getTeamsChannels(teamId)
    );

    useEffect(() => {
        if(teamId && teamId !== ""){
            qc.invalidateQueries(["channels", teamId])  
        }
    }, [teamId])
    
    return { channels, isLoadingChannels, setTeamId };
}

