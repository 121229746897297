import { Modal } from "@fluentui/react";
import * as React from "react";
import { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { GetPopUpInfo } from "../utils/Helper";
import { Iitems, ISelectedItem } from "../utils/interfaces";
import * as microsoftTeams from "@microsoft/teams-js";

import "./PopUp.css";
import { PrimaryButton, ProgressIndicator } from "@fluentui/react";

const modalDesktopStyle = {
  scrollableContent: {
    width: "80vw",
    height: "70vh",
    padding: "40px",
  },
  main: {
    width: "calc(80vw+40px)",
    height: "calc(70vw+40px)",
  },
};
const modalMobileStyle = {};

interface MessageCenterPopUpProps {
  isPopUpOpen: boolean;
  setIsPopUpOpen: any;
  selectedItem: Iitems | undefined;
  setSelectedItem: any;
}

export default function Popup({
  isPopUpOpen,
  setIsPopUpOpen,
  selectedItem,
  setSelectedItem,
}: MessageCenterPopUpProps) {
  const [textMessage, setTextMessage] = useState<ISelectedItem>();
  const [windowWidth] = useWindowSize();

  useEffect(() => {
    if (selectedItem) {
      GetPopUpInfo(setTextMessage, selectedItem.id);
    }
  }, [selectedItem]);
  if (!selectedItem) {
    return null;
  }
  const handleClose = () => {
    setIsPopUpOpen(false);
    setTextMessage(undefined);
    setSelectedItem(undefined);
  };

  return (
    <div>
      <Modal
        allowTouchBodyScroll={false}
        isOpen={isPopUpOpen}
        isBlocking={false}
        onDismiss={handleClose}
        styles={windowWidth > 1000 ? modalDesktopStyle : modalMobileStyle}
      >
        <div key={textMessage?.messageId}>
          <header>
            <h1>{selectedItem?.messageTitle}</h1>
            <span>
              {textMessage?.messageId} • {selectedItem.lastUpdatedTime}
            </span>
            <a className="close" onClick={handleClose}>
              ×
            </a>
          </header>
          <div className="info-container">
            <div>
              <div>
                <h4> Category</h4>
                <p>{selectedItem.category}</p>
              </div>
              <div>
                <h4> Severity</h4>
                <p>{selectedItem.severity}</p>
              </div>
            </div>
            <div>
              <h4> Services</h4>
              <p>{renderIcons(selectedItem.service)}</p>
            </div>
          </div>

          {textMessage ? (
            <>
              <hr />
              <div
                dangerouslySetInnerHTML={{ __html: textMessage?.messageText }}
                className="popup-content"
              />
            </>
          ) : (
            <ProgressIndicator />
          )}
        </div>
        <PrimaryButton
          className="closebutton"
          onClick={handleClose}
          text="Close"
          iconProps={{
            iconName: "ChromeClose",
            style: {
              fontSize: "14px",
            },
          }}
        />
      </Modal>
    </div>
  );
}

const renderIcons = (services: string[]): React.ReactNode => {
  return services.map((serviceName: string) => {
    if (
      serviceName === "Exchange Online" ||
      serviceName === "Exchange Online Protection"
    ) {
      return (
        <>
          <i
            data-icon-name="ExchangeLogo"
            className={"ms-Icon root-32 css-685 iconStyles-856"}
            aria-label="Exchange Online"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "OneDrive for Business") {
      return (
        <>
          <i
            data-icon-name="OneDrive"
            className={"ms-Icon root-32 css-392 iconStyles-856"}
            aria-label="OneDrive for Business"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (
      serviceName === "SharePoint Online" ||
      serviceName === "SharePoint Syntex"
    ) {
      return (
        <>
          <i
            data-icon-name="SharepointLogo"
            className={"ms-Icon root-32 css-685 iconStyles-848"}
            aria-label="SharePoint Online"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (
      serviceName === "Dynamics 365" ||
      serviceName === "Dynamics 365 Business Central"
    ) {
      return (
        <>
          <i
            data-icon-name="Dynamics365Logo"
            className={"ms-Icon root-32 css-683 iconStyles-860"}
            aria-label="Dynamics 365"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Microsoft 365 suite") {
      return (
        <>
          <i
            data-icon-name="AdminALogo32"
            className={"ms-Icon root-32 css-681 iconStyles-852"}
            aria-label="Microsoft 365 suite"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Yammer Enterprise") {
      return (
        <>
          <i
            data-icon-name="YammerLogo"
            className={"ms-Icon root-32 css-539 iconStyles-862"}
            aria-label="Yammer"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Microsoft Teams") {
      return (
        <>
          <i
            data-icon-name="TeamsLogo"
            className={"ms-Icon root-32 css-685 iconStyles-858"}
            aria-label="Microsoft Teams"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (
      serviceName === "Microsoft Power Automate in Microsoft 365" ||
      serviceName === "Microsoft Power Automate"
    ) {
      return (
        <>
          <i
            data-icon-name="MicrosoftFlowLogo"
            className="ms-Icon root-32 css-681 iconStyles-868"
            aria-label="Microsoft Power Automate in Microsoft 365"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Skype for Business") {
      return (
        <>
          <i
            data-icon-name="SkypeLogo"
            className={"ms-Icon root-32 css-678 iconStyles-856"}
            aria-label="Skype for Business"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (
      serviceName === "Identity Service" ||
      serviceName === "Microsoft Defender for Endpoint"
    ) {
      return (
        <>
          <i
            data-icon-name="Product"
            className={"ms-Icon root-32 css-539 iconStyles-852"}
            aria-label="Identity Service,Microsoft Defender for Endpoint"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Planner") {
      return (
        <>
          <i
            data-icon-name="PlannerLogo"
            className={"ms-Icon root-32 css-683 iconStyles-853"}
            aria-label="Planner"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Microsoft Forms") {
      return (
        <>
          <i
            data-icon-name="OfficeFormsLogo"
            className="ms-Icon root-32 css-681 iconStyles-859"
            aria-label="Microsoft Forms"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (
      serviceName === "Power Apps in Microsoft 365" ||
      serviceName === "Power Apps"
    ) {
      return (
        <>
          <i
            data-icon-name="PowerAppsLogo"
            className={"ms-Icon root-32 css-843 iconStyles-869"}
            aria-label="Power Apps in Microsoft 365"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else if (serviceName === "Power BI") {
      return (
        <>
          <i
            data-icon-name="PowerAppsLogo"
            className="ms-Icon ms-Icon--PowerBILogo"
          />
          <span className="icon-label">{serviceName}</span>
        </>
      );
    } else {
      return (
        <>
          <i
            data-icon-name="OfficeLogo"
            className={"ms-Icon root-32 css-678 iconStyles-854"}
            aria-label="Office for the web"
          >
            
          </i>
          <span className="icon-label">{serviceName}</span>
        </>
      );
    }
  });
};
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
