import "./MessageCenterDemo.css";
import styles from "./MessageCenter.module.css";
import {
  filterFunction,
  generateArrayOfYears,
  getInitialData,
  getMicrosoftServices,
  getNewData,
  desktopColumns,
  mobileColumns,
  prepareCSVData,
} from "../utils/Helper";
import { useState, useEffect } from "react";
import Popup from "./PopUp";
import RenderColumns from "./renderColumns";
import {
  Dropdown,
  IconButton,
  IIconProps,
  PrimaryButton,
  ProgressIndicator,
  SearchBox,
  DetailsList,
  DetailsListLayoutMode,
  Toggle,
} from "@fluentui/react";
import { IDropdownOption } from "@fluentui/react";
import SharePopUp from "./SharePopUp";
import { useLayoutEffect } from "react";
import Header from "../../../commonComponents/Header";
import DownloadButtonPlusAPI from "./DownloadButtonPlusAPI";
import { IMessage, IMicrosoftServices } from "../../../models/models";
export default function MessageCenterDemo() {
  const [items, setItems] = useState<IMessage[] | any[]>([]);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IMessage>();
  const [isSharePopUpOpen, setIsSharePopUpOpen] = useState<boolean>(false);
  const [selectedShareItem, setSelectedShareItem] = useState<IMessage>();
  const [microsoftServices, setMicrosoftServices] = useState<IDropdownOption[]>(
    []
  );
  const [filteredItems, setFilteredItems] = useState<IMessage[] | any[]>([]);
  const [serviceFilterValue, setServiceFilterValue] = useState<string | null>(
    null
  );
  const [keyWordFilterValue, setKeyWordFilterValue] = useState<string>("");
  const [favoriteFilter, setFavoriteFilter] = useState<boolean>(false);
  const [archiveFilter, setArchiveFilter] = useState<boolean>(false);
  const [test, setTest] = useState();

  const [windowWidth] = useWindowSize();

  useEffect(() => {
    getInitialData(setItems);
    getMicrosoftServices(setMicrosoftServices);
  }, []);

  useEffect(() => {
    if (filteredItems.length === 0) {
      setFilteredItems(items);
    }
  }, [items]);

  const handlesServiceFilterChange = (text: IMicrosoftServices) => {
    setServiceFilterValue(text.key);
    filterFunction(
      keyWordFilterValue,
      text.key,
      favoriteFilter,
      items,
      setFilteredItems
    );
  };
  const handlesYearFilterChange = (text: IMicrosoftServices) => {
    setItems([]);
    getNewData(
      setItems,
      text.key,
      serviceFilterValue,
      keyWordFilterValue,
      favoriteFilter,
      setFilteredItems
    );
  };
  const handleSubmit = () => {
    filterFunction(
      keyWordFilterValue,
      serviceFilterValue,
      favoriteFilter,
      items,
      setFilteredItems
    );
  };
  const handleKeyWordFilterChange = (newValue: string) => {
    setKeyWordFilterValue(/^\s/.test(newValue) ? "" : newValue);
  };
  const handleReset = () => {
    setKeyWordFilterValue("");
    setServiceFilterValue(null);
    setFilteredItems(items);
    setFavoriteFilter(false);
  };
  const handleClearKeyWordFilter = () => {
    setKeyWordFilterValue("");
    filterFunction(
      "",
      serviceFilterValue,
      favoriteFilter,
      items,
      setFilteredItems
    );
  };
  const handleFavoriteToggle = (checked: boolean) => {
    setFavoriteFilter(checked);
    filterFunction(
      keyWordFilterValue,
      serviceFilterValue,
      checked,
      items,
      setFilteredItems
    );
  };

  const archivedItems = (filteredItems: IMessage[], archiveFilter: boolean) => {
    if (archiveFilter) {
      const newArray = filteredItems.filter((item) => item.isArchived == true);
      return newArray;
    } else {
      const newArray = filteredItems.filter((item) => item.isArchived == false);
      return newArray;
    }
  };

  return (
    <>
      <Header title="Message Center" icon="MessageFill" />
      <div className="archive-container">
        <div>
          <span
            onClick={() => setArchiveFilter(false)}
            className={archiveFilter ? undefined : "archive-container-active"}
          >
            Inbox
          </span>
          <span
            onClick={() => setArchiveFilter(true)}
            className={archiveFilter ? "archive-container-active" : undefined}
          >
            Archive
          </span>
        </div>
        <Toggle
          className="toggle"
          inlineLabel
          onText="All"
          offText="Favorites"
          onChange={(e, checked: any) => handleFavoriteToggle(checked)}
        />
      </div>
      <hr></hr>
      <div className="input-container">
        <div className="dropdown-container">
          <Dropdown
            options={microsoftServices}
            className="dropdown"
            onChange={(e, text: any) => handlesServiceFilterChange(text)}
            selectedKey={serviceFilterValue}
            label="Service"
            placeholder="None"
          />
          <Dropdown
            options={generateArrayOfYears()}
            className="dropdown"
            onChange={(e, text: any) => handlesYearFilterChange(text)}
            label="Year"
            placeholder={new Date().getFullYear().toString()}
          />
        </div>
        <div className="searchBar-container">
          <div className="keyword-container">
            <p>Keyword</p>
            <SearchBox
              placeholder="Search... "
              onChange={(e, text) => handleKeyWordFilterChange(text ?? "")}
              value={keyWordFilterValue}
              className="textfield"
              labelText="Filter by Keyword"
              onSearch={handleSubmit}
              onClear={() => handleClearKeyWordFilter()}
            />
          </div>
          <div className="button-container">
            <PrimaryButton text="Search" onClick={handleSubmit} />
            <IconButton
              iconProps={{ iconName: "ClearFilter" }}
              onClick={handleReset}
              className={styles.IconButton}
              title="Reset all filters"
              ariaLabel="Reset all filters"
            />
            <DownloadButtonPlusAPI
              csvData={filteredItems}
              csvFilename="MessageCenter.csv"
            />
          </div>
        </div>
      </div>
      {items.length > 0 ? (
        <div className="list-container">
          <DetailsList
            items={archivedItems(filteredItems, archiveFilter)}
            columns={windowWidth > 1000 ? desktopColumns : mobileColumns}
            getKey={(item) => item.key}
            selectionMode={0}
            setKey="multiple"
            layoutMode={DetailsListLayoutMode.justified}
            onRenderItemColumn={(
              item: IMessage,
              index: number | undefined,
              column: any
            ) =>
              RenderColumns(
                item,
                column,
                isPopUpOpen,
                setIsPopUpOpen,
                setSelectedItem,
                filteredItems,
                setFilteredItems,
                setIsSharePopUpOpen,
                setSelectedShareItem
              )
            }
          />
          <Popup
            isPopUpOpen={isPopUpOpen}
            setIsPopUpOpen={setIsPopUpOpen}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
          <SharePopUp
            isPopUpOpen={isSharePopUpOpen}
            setIsPopUpOpen={setIsSharePopUpOpen}
            selectedItem={selectedShareItem}
            setSelectedShareItem={setSelectedShareItem}
          />
        </div>
      ) : (
        <ProgressIndicator />
      )}
    </>
  );
}
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
