
interface ButtonProps {
    children: React.ReactNode;
    handleClick: () => void;
    classes?: string;
}
export const BasicButton = ({children, handleClick, classes}:ButtonProps) => {
    return <button className={`${classes || ''} px-4 py-1 text-white rounded bg-sky-700 hover:bg-sky-900 transition duration-100 ease-in-out `} onClick={handleClick}>{children}</button>
}
export const CancelButton = ({children, handleClick, classes}:ButtonProps) => {
    return <button className={`${classes || ''} px-4 py-1 text-white rounded bg-red-500 hover:bg-red-700 transition duration-100 ease-in-out `} onClick={handleClick}>{children}</button>
}