


export async function GetAzurePage(setState: any) {
  await fetch("/demoStatus.html")
  .then(res => res.text())
  .then(data => data ? setState(data) : setState("A connection error occurred") )
}



export async function GetAzurePageAPI(setState: any) {
  setState(5);
}


