import { IHistoryItem } from "../../../models/models";
import * as microsoftTeams from "@microsoft/teams-js";
import { formatDate, formatDateTime } from "../../../helpers/helpers";




export async function getInitialData(
  setState: any,
  context: microsoftTeams.Context,
  token: string
) {
  const data = {
    TenantId: context.tid,
    TeamSiteDomain: context.teamSiteDomain,
    UserPrincipalName: context.userPrincipalName,
  };
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/GetServiceHealthForTenant",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  const rawData = await response.json();

  const prettyData = rawData.map((data: any) => {
    return {
      id: data.id,
      services: data.workloadDisplayName,
      messages: data.messages.map((message: any) => {
        return {
          lastUpdatedTime: formatDate(message.lastUpdatedTime),
          startTime: formatDate(message.startTime),
          title: message.title,
          id: message.id,
        };
      }),
      status: data.status,
      statusDisplayName: data.statusDisplayName,
    };
  });

  const alphSortedData = [...prettyData].sort((a: IHistoryItem, b: IHistoryItem) =>
    a.service.localeCompare(b.service)
  );

  //sort by status of the service: Services in priority: ServiceInterruption,PostIncidentReviewPublished, ServiceDegradation, ExtendedRecovery, ServiceRestored, ServiceOperational

  const statusSort = [...alphSortedData].sort(
    (a: IHistoryItem, b: IHistoryItem) => {
      if (a.status === "ServiceInterruption") {
        return -1;
      } else if (b.status === "ServiceInterruption") {
        return 1;
      } else if (a.status === "PostIncidentReviewPublished") {
        return -1;
      } else if (b.status === "PostIncidentReviewPublished") {
        return 1;
      } else if (a.status === "ServiceDegradation") {
        return -1;
      } else if (b.status === "ServiceDegradation") {
        return 1;
      } else if (a.status === "ExtendedRecovery") {
        return -1;
      } else if (b.status === "ExtendedRecovery") {
        return 1;
      } else if (a.status === "ServiceRestored") {
        return -1;
      } else if (b.status === "ServiceRestored") {
        return 1;
      } else if (a.status === "ServiceOperational") {
        return -1;
      } else if (b.status === "ServiceOperational") {
        return 1;
      } else {
        return 0;
      }      
    }
  );

  setState(statusSort);
}

export async function getMessagesData(
  setState: any,
  messageId: string,
  context: microsoftTeams.Context,
  token: string
) {
  const data = {
    TenantId: context.tid,
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/GetIncidentsForMessage/${messageId}/${data.TenantId}`,
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  const rawData = await response.json();
  const prettyData = rawData.map((data: any) => {
    return {
      publishTime: formatDateTime(data.publishedTime),
      messageText: data.messageText,
    };
  });
  setState(prettyData);
}


