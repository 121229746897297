import { formatDateTime } from "../helpers/helpers";
import { IIncidentItem } from "../models/models";
import { BasicButton } from "./Buttons";


interface IMessageListElementProps {
    message: IIncidentItem;
    handleClick: (message: IIncidentItem) => void;
  }


 export const ServiceOkElement = () => {
  return (
    <div className="flex flex-col items-start content-center px-4 pt-4">
      <h4 className="text-lg font-semibold text-sky-700 md:text-xl">All Services are running</h4>
      <p className="mb-1">There are no active incidents</p>
    </div>
  )
 }

 export const RenderMessages = ({messages, handleClick}: {messages: IIncidentItem[], handleClick: (item: IIncidentItem) => void}) => {
  return (
    <>
      {messages.length > 0 ? messages.map((message) => <MessageListElement message={message}  key={message.id} handleClick={() => handleClick(message)}/>) : <ServiceOkElement />}
    </>
  )
 }
  


 export const MessageListElement = ({message, handleClick}:IMessageListElementProps) => { 
  return (
      <div className="flex flex-col items-start content-center px-4 pt-4 ">
        <h2 className="mb-1 font-semibold text-sky-700 md:text-lg">{message.title}</h2>
        <p className="mb-1">Estimated start time: {message.startTime}</p>
        <p className="mb-2">LastUpdate: {message.lastUpdatedTime}</p>
        <BasicButton handleClick={() => handleClick(message)} classes="w-full lg:w-auto">Read more</BasicButton>    
      </div>        
  )
}
//  export const MessageListElement = ({message, handleClick}:IMessageListElementProps) => { 
//   return (
//       <div className="flex flex-col items-start content-center w-full p-4 mb-4 rounded-md shadow-lg">
//         <h2 className="mb-1 text-lg font-semibold text-sky-700 md:text-xl">{message.title}</h2>
//         <p className="mb-1">Estimated start time: {formatDateTime(message.startDateTime)}</p>
//         <p className="mb-2">LastUpdate: {formatDateTime(message.lastModifiedDateTime)}</p>
//         <BasicButton handleClick={() => handleClick(message)} classes="w-full lg:w-auto">Read more</BasicButton>    
//       </div>        
//   )
// }