import { useEffect, useState } from "react";
import axios from "axios";
import { app, authentication } from "@microsoft/teams-js";
import { useMsal } from "@azure/msal-react";
import { useScopedAuth } from "../hooks/useScopedAuth";

export const useAuth = () => {
  const { handleInitToken } = useScopedAuth()
  const [isTokenSet, setIsTokenSet] = useState(false);
  const { instance, accounts } = useMsal();

  const getToken = async () => {
    return new Promise((resolve) => {
      app.initialize()

      authentication.getAuthToken().then((result) => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + result;
        resolve(true);
        setIsTokenSet(true)
        handleInitToken()
      }).catch((error) => {
        app.notifyFailure({
          reason: app.FailedReason.AuthFailed,
          message: error
        });
        console.log("token unable to be set");
        resolve(false);
      });
    });
  }
  useEffect(() => {
    getToken()
  }, [instance, accounts]);

  return { isTokenSet };
};

export const getNewAxiosToken = async () => {
  return new Promise((resolve) => {
    app.initialize()

    authentication.getAuthToken().then((result) => {
      // console.log(result)
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + result;
      resolve(result);

    }).catch((error) => {
      app.notifyFailure({
        reason: app.FailedReason.AuthFailed,
        message: error
      });
      console.log("token unable to be set");
      resolve(null);
    });
  });
}