
import { prettifyMessages } from './../helpers/helpers';
import axios from "axios";
import { sortMessages } from "../helpers/helpers";
import { IMessageDetailRequest, IMessageRequest, ISetMessageStatusRequest, SendMessageModel } from "./models";
import { IMicrosoftServicesWithoutNoneOption } from '../models/models';
import { graphAxios } from "..";
import { ChatMessageProps } from '@fluentui/react-northstar';
import { IPersonaProps } from 'office-ui-fabric-react';
import { globalConfig } from './retry.config';
// api://atworkcloudmonitor-dev.azurewebsites.net/4fa51e6c-0bfe-4598-8348-676a3cfdc65e
export const fetchMessages = async (data: IMessageRequest) => {
  const res = await axios.post(`/GetMessagesForTenant`, data);
  if (!res.data.succeeded) return [];
  const { messages } = res.data;
  return sortMessages(prettifyMessages(messages))
}
export const fetchMessageModalData = async ({ messageId, TenantId }: IMessageDetailRequest) => {
  const res = await axios.post(`/GetIncidentsForMessage/${messageId}/${TenantId}`, {}, globalConfig);
  return res.data[0];
}

export const setMessageStatus = async (data: ISetMessageStatusRequest) => {
  const res = await axios.post(`/SetMessageStatus`, data, globalConfig);
  return res.status === 200 ? true : false;
}

export const SendEmail = async (emailData: {}) => {
  const res = await graphAxios.post(`me/sendMail`, emailData, globalConfig);
  return res.status === 202 ? true : false;
}
/*   export  const SendMessage = async (data: SendMessageModel) => {
    const res = await axios.post(`/PostChannelMessage`, data);
    return res.data
  } */
export const InitChat = async (data: IChatMessage) => {
  const url = `chats`
  const res = await graphAxios.post(url, data, globalConfig);
  return res.data.id

}
export const SendChatMessage = async (data: SendMessageModel) => {
  const url = `chats/${data.ChatId}/messages`;
  const params = {
    body: {
      content: data.Message,
      contentType: "html"
    }
  }
  return graphAxios.post(url, params, globalConfig);
}
export const SendChannelMessage = async (data: SendMessageModel) => {
  
  const url = `teams/${data.TeamId}/channels/${data.ChannelId}/messages`;
  const params = {
    subject: data.Subject,
    body: {
      content: data.Message,
      contentType: "html"
    }
  }
  const res = await graphAxios.post(url, params, globalConfig);
  return res.data
}
/*  export const getTeams = async (data: IRequestBase) => {
   const res = await axios.post(`/GetTeams`, data);
   const prettyData = res.data.teams.map((team: any) => {
       return {
           text: team.displayName,
           key: team.id,
       };
   });
   return prettyData;
   }  
   */
export const getTeams = async () => {
  const url = "me/joinedTeams"
  const res = await graphAxios.get(url, globalConfig);
  const prettyData = res.data.value.map((team: any) => {
    return {
      text: team.displayName,
      key: team.id,
    };
  });
  return prettyData;
}
export const getTeamsChannels = async (teamId: string) => {

  if (!teamId || teamId === "") return [];

  const url = `teams/${teamId}/channels`
  const res = await graphAxios.get(url, globalConfig);
  const prettyData = res.data.value.map((team: any) => {
    return {
      text: team.displayName,
      key: team.id,
    };
  });
  return prettyData;
}

/*   export const getChannels = async (data: IChannelRequest) => {
    const res = await axios.post(`/GetChannels`, data);
    const prettyData = res.data.channels.map((channel: any) => {
        return {
            text: channel.displayName,
            key: channel.id,
        };
    });
    return prettyData;
    } */
/* export const getSearchPeople = async (data: IGetSearchPeopleRequest): Promise<IPersonaProps[]> => {
    const res = await axios.post(`/GetUserBySearch`, data);
    const prettyData = res.data.users.map((user: any) => {
        return {
            text: user.displayName,
            key: user.mail,
        };
    });
    return prettyData;
} */
export const getSearchPeople = async (searchTerm: string): Promise<IPersonaProps[]> => {
  const res = await graphAxios.get(`users?$filter=startswith(displayName,'${searchTerm}')`, globalConfig);
  const { value } = res.data;
  const prettyData = value.map((user: any) => {
    return {
      text: user.displayName,
      email: user.mail,
      id: user.id,
    };
  });
  return prettyData;
}
/* export const getChats = async () => {
  const url = `me/chats`
  const res = await graphAxios.get(url);
  const prettyData = res.data.value.map((team: any) => {
      return {
          text: team.topic,
          key: team.id,
      };
  });
  return prettyData;
  }   */

export interface ICSVExportRequest {
  messageId: string;
  TenantId: string;
}
export const getItemsForCSVExport = async ({ TenantId, messageId }: ICSVExportRequest) => {
  const res = await axios.post(`/GetIncidentsForMessage/${messageId}/${TenantId}`,{}, globalConfig);
  return res.data[0].messageText;
}

export const fetchMicrosoftServices = async (tenantId: string) => {
  const res = await axios.post(`/GetServicesForTenant/${tenantId}`,{},globalConfig);
  const prettyData = res.data.map((service: any) => {
    return {
      key: service.workloadDisplayName,
      text: service.workloadDisplayName,
    };
  });
  const prettySortedData = [...prettyData].sort(
    (
      a: IMicrosoftServicesWithoutNoneOption,
      b: IMicrosoftServicesWithoutNoneOption
    ) => a.key.localeCompare(b.key)
  );

  prettySortedData.unshift({ key: null, text: "None" });
  return prettySortedData;
}



export interface IChatMessage {
  chatType: "group" | "oneOnOne";
  topic?: string;
  members: IChatMember[];
}

export interface IChatMember {
  "@odata.type": "#microsoft.graph.aadUserConversationMember",
  "roles": ["owner" | "guest"],
  "user@odata.bind": string
}
