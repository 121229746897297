import { useEffect, useRef, useState } from "react";
import {
  GetAzurePage,
} from "./helper";

export default function AzureStatusDemo() {
  const [azurePageHTML, setAzurePageHTML] = useState<string>("");
  const [displayHTML, setDisplayHTML] = useState(false);
  const myRef = useRef(null);
  useEffect(() => {
    GetAzurePage(setAzurePageHTML);
  }, []);

  useEffect(() => {
    if (azurePageHTML.length !== 0) {
     
      //neccessary because of delayed css loading
      setTimeout(() => {
        setDisplayHTML(true);
      }, 1000);
    }
  }, [azurePageHTML]);

  return (
    <>    

      {azurePageHTML.length !== 0 ? (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: azurePageHTML }}
            ref={myRef}
            style={{ opacity: displayHTML ? 1 : 0 }}
          />         
        </>
      ) : null}
    </>
  );
}
