import axios from "axios";
import { formatDateTime, prettifyIncidents, sortIncidents } from "../helpers/helpers";
import { IIncidentMessageRequest, IMessageRequest } from "./models";
import { graphAxios } from "..";
import { globalConfig } from "./retry.config";


export const fetchIncidents = async (data:IMessageRequest) => {
    const res = await axios.post(`/GetServiceHealthForTenant`, data, globalConfig);
    if(!res.data) return [];
    return prettifyIncidents(res.data)
}
export const getServices = async () => {
    const res = await graphAxios.get(`admin/serviceAnnouncement/healthOverviews/`, globalConfig);    
    return res.data.value
}
export const getIncidents = async (serviceName: string) => {
    const res = await graphAxios.get(`admin/serviceAnnouncement/healthOverviews/?$filter=service eq '${serviceName}'&$expand=issues($filter=isResolved eq false)`, globalConfig);
    if(res.status === 403) return []
    return res.data.value[0];
}

export const fetchIncidentMessages = async ({messageId, TenantId}: IIncidentMessageRequest) => {
    const res = await axios.post(`/GetIncidentsForMessage/${messageId}/${TenantId}`,{}, globalConfig);
    const prettyData = res.data.map((data: any) => {
        return {
            publishTime: data.publishedTime,
            messageText: data.messageText,
        };
    });
    return prettyData;
}
