import {
  DefaultButton,
  DirectionalHint,
  Dropdown,
  IconButton,
  IDropdownOption,
  ITooltipProps,
  Modal,
  Spinner,
  SpinnerSize,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import "./SharePopUp.css";
import React, { useEffect, useState } from "react";
import { Iitems, ISelectedItem } from "../utils/interfaces";
import { GetChannels, GetPopUpInfo, GetTeams } from "../utils/Helper";
import PeoplePicker, { Person } from "./PeoplePicker";
import { handleOpenLink } from "../../../helpers/helpers";

interface IPopUpProps {
  isPopUpOpen: boolean;
  setIsPopUpOpen: any;
  selectedItem: Iitems | undefined;
  setSelectedShareItem: any;
}

export default function SharePopUp({
  isPopUpOpen,
  setIsPopUpOpen,
  selectedItem,
  setSelectedShareItem,
}: IPopUpProps) {
  const [channels, setChannels] = useState<IDropdownOption[]>();
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const [selectedPeople, setSelectedPeople] = useState<Person[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [teams, setTeams] = useState<IDropdownOption[]>();
  const [selectedTeam, setSelectedTeam] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [additionalInformation, setAdditionalInformation] =
    useState<ISelectedItem>();

  useEffect(() => {
    if (selectedItem) {
      GetPopUpInfo(setAdditionalInformation, selectedItem.id);
      GetTeams(setTeams);
    }
    setSubject(selectedItem?.id + "-" + selectedItem?.messageTitle);
  }, [selectedItem]);

  useEffect(() => {
    if (selectedTeam) {
      GetChannels(setChannels, selectedTeam);
    }
  }, [selectedTeam]);

  const handleSubmit = () => {
    const eMailList = selectedPeople.map((person) => {
      return person.key;
    });
    if (selectedChannel) {
      eMailList.push(selectedChannel);
    }

    if (additionalInformation && eMailList.length > 0) {
      handleClose();
      setIsPopUpOpen(false);
    } else if (eMailList.length < 1) {
      setErrorMessage("Please choose a Channel and/or Person!");
    }
  };

  const handleTeamsDropdownChange = (text: string) => {
    setChannels(undefined);
    setSelectedTeam(text);
  };
  const handleClose = () => {
    setIsPopUpOpen(false);
    setSelectedChannel("");
    setSelectedTeam("");
    setSelectedPeople([]);
    setTeams(undefined);
    setChannels(undefined);
    setErrorMessage(undefined);
    setSelectedShareItem(undefined);
  };

  return (
    <Modal
      allowTouchBodyScroll={false}
      isOpen={isPopUpOpen}
      isBlocking={false}
      onDismiss={handleClose}
      styles={modalStyle}
      isDarkOverlay={false}
    >
      <div key={selectedItem?.id} className="sharepopup-container">
        <h1>Sharing: {selectedItem?.id}</h1>
        <Dropdown
          options={teams!}
          onChange={(e, text: any) => handleTeamsDropdownChange(text.key)}
          onRenderLabel={() => renderTeamsLabel(teams)}
          className="textfields"
          placeholder="None"
        />
        <Dropdown
          options={channels!}
          onChange={(e, text: any) => setSelectedChannel(text.key)}
          onRenderLabel={() => renderChannelLabel(channels, selectedTeam)}
          className="textfields"
          placeholder="None"
          disabled={selectedTeam ? false : true}
        />
        <PeoplePicker
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
        />
        <p className="errorMessage"> {errorMessage}</p>

        <div className="button-container">
          <DefaultButton
            text="Share Message"
            iconProps={{ iconName: "Share" }}
            allowDisabledFocus
            onClick={handleSubmit}
            className="button"
          />

          <DefaultButton
            text="Cancel"
            iconProps={{ iconName: "Cancel" }}
            allowDisabledFocus
            onClick={handleClose}
            className="button"
          />
        </div>
      </div>
    </Modal>
  );
}

const renderTeamsLabel = (teams: any): JSX.Element => {
  return (
    <>
      <Stack horizontal verticalAlign="center">
        <i className="ms-Icon ms-Icon--TeamsLogo" />
        <span>Select the Team you want to post</span>
        {teams ? null : <Spinner size={SpinnerSize.small} />}
      </Stack>
    </>
  );
};
const renderChannelLabel = (channel: any, teams: any): JSX.Element => {
  const iconPicker = (channel: any, selectedTeam: any) => {
    if (selectedTeam && !channel) {
      return <Spinner size={SpinnerSize.small} />;
    } else if (selectedTeam && channel.length === 0) {
      return (
        <>
          <TooltipHost
            tooltipProps={tooltipProps}
            id="ErrorToolTip"
            directionalHint={DirectionalHint.bottomCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <IconButton
              iconProps={{ iconName: "Error" }}
              aria-describedby="ErrorToolTip"
            />
          </TooltipHost>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <i className="ms-Icon ms-Icon--TeamsLogo" />
        <span>Select the Channel you want to post</span>
        {iconPicker(channel, teams)}
      </Stack>
    </>
  );
};
const tooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <>
      <p>
        An error has occurred! The selected Team doesn't have any channels with
        the required email-permission.
        <br /> Please follow{" "}
        <a
          href="https://teams.handsontek.net/2019/09/12/how-to-send-an-email-to-a-channel/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleOpenLink}
        >
          theses steps.
        </a>
      </p>
    </>
  ),
};

const modalStyle = {
  scrollableContent: {
    padding: "40px",
  },
};
