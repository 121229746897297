// import * as microsoftTeams from "@microsoft/teams-js";
import { app, authentication } from "@microsoft/teams-js";
import axios from "axios";

// export async function isLoggedIn(
//   token: string,
//   context: app.Context
// ) {
//   const data = {
//     TenantId: context.tid,
//     TeamSiteDomain: context.teamSiteDomain,
//     UserPrincipalName: context.userPrincipalName,
//   };
//   const response = await fetch(
//     process.env.REACT_APP_API_URL + "/GetTenantIsApproved",
//     {
//       method: "POST",
//       body: JSON.stringify(data),
//       headers: {
//         Authorization: "Bearer " + token,
//         "Content-Type": "application/json",
//       },
//     }
//   );

//   const responseData = await response.json();
//   return responseData;
// }


export async function getToken(setToken: any) {
  authentication.getAuthToken().then((result) => {
      setToken(result);
      axios.defaults.headers.common["Authorization"] = "Bearer " + result;
      app.notifySuccess();
  }).catch((error) => {
    app.notifyFailure({
      reason: app.FailedReason.AuthFailed,
      message: error
    });
    
  });
  // microsoftTeams.authentication.getAuthToken({
  //   successCallback: (token: string) => {
  //     setToken(token);
  //     axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  //     microsoftTeams.appInitialization.notifySuccess();
  //   },
  //   failureCallback: (message: string) => {
  //     microsoftTeams.appInitialization.notifyFailure({
  //       reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
  //       message,
  //     });
  //   },
  // });
}

/* export async function isDataAvailable(
  context: microsoftTeams.Context,
  token: string,
) {
  const data = {
    TenantId: context.tid,
    TeamSiteDomain: context.teamSiteDomain,
    UserPrincipalName: context.userPrincipalName,
    Year: new Date().getFullYear(),
    MessageType: "MessageCenter",
  };

  const messageCenterResponse = await fetch(
    process.env.REACT_APP_API_URL + "/GetMessagesForTenant",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  const incidentCenterResponse = await fetch(
    process.env.REACT_APP_API_URL + "/GetServiceHealthForTenant",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  const messageCenterData = await messageCenterResponse.json();
  const incidentCenterData = await incidentCenterResponse.json();

  if (
    incidentCenterData.length > 0 &&
    messageCenterData.succeeded &&
    messageCenterData.messages.length > 0
  ) {
    setState(true);
  } else {
    setState(false);
  }
} */
// export async function isIncidentCenterDataAvailable(
//   context: microsoftTeams.Context,
//   token: string,
//   setState: any
// ) {
//   const data = {
//     TenantId: context.tid,
//     TeamSiteDomain: context.teamSiteDomain,
//     UserPrincipalName: context.userPrincipalName,
//   };
//   try {
//     const response = await fetch(url + "/GetServiceHealthForTenant", {
//       method: "POST",
//       body: JSON.stringify(data),
//       headers: {
//         Authorization: "Bearer " + token,
//         "Content-Type": "application/json",
//       },
//     });
//     const rawData = await response.json();
//     if (rawData.length > 0) {
//       setState(true);
//     } else {
//       setState(false);
//     }
//   } catch {
//     setState(false);
//   }
// }

// export interface IIsLoggedIn {
//   loadingStatus: number;
//   approved: boolean;
//   approveLink: string;
//   message: string;
// }

/* export const callIsLoggedInAndMessagesInOrder = async (
  context: microsoftTeams.Context,
  token: string,
  setLoggedIn: any,
) => {
  const loggedInStatus = await isLoggedIn(token, context);
  setLoggedIn(loggedInStatus);
  if (loggedInStatus?.approved) {
    await isDataAvailable(context, token);
  }
};
 */
// export async function getToken(
//   setToken: any,
//   setLoggedIn: any,
//   context: microsoftTeams.Context
// ) {
//   microsoftTeams.authentication.getAuthToken({
//     successCallback: (token: string) => {
//       setToken(token);
//       isLoggedIn(
//         setLoggedIn,
//         token,
//         context?.userPrincipalName ?? "",
//         context?.tid ?? "",
//         context?.teamSiteDomain ?? ""
//       );

//       microsoftTeams.appInitialization.notifySuccess();
//     },
//     failureCallback: (message: string) => {
//       microsoftTeams.appInitialization.notifyFailure({
//         reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
//         message,
//       });
//     },
//   });
// }
