
export const CloudHubDivider = ({count, wide}: {count:number, wide?:true}) => {
   
   const maxWidth = wide ? "w-full" : "max-w-screen-lg"
    return <div className={`w-full my-8 h-[1px] bg-sky-700/[0.8] relative z-1 `} >
                <div className={`relative flex ${maxWidth} mx-auto z-2`}>
                    <div className="absolute flex px-2 bg-white z-3 left-2 -top-3">
                        { count }
                        { count === 1 ? <> Item</> : <> Items</> }
                    </div>
                </div>
            </div>
}