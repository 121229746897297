/* 
import {
  ProgressIndicator,
} from "office-ui-fabric-react";
import {  IIncidentItem } from "../../../models/models";
import { formatDateTime } from "../../../helpers/helpers";



interface ICardProps {
  handleClose: () => void
  activeMessage: IIncidentItem
}
export const MessageModal = ({ handleClose, activeMessage }: ICardProps) =>  {
  return (
    <div className="bg-black/[0.7] fixed top-0 left-0 z-5 w-full h-full d-flex items-center justify-center">      
        <div className="max-w-screen-lg mx-2 bg-white rounded-lg shadow">
          <div className="relative w-full px-2 py-3 rounded-t bg-sky-700 ">
            <h1 className="px-4 m-0 text-lg md:text-2xl text-sky-50">{activeMessage.title}</h1>
            <button className="absolute top-0 right-0 px-2 text-3xl transition duration-100 ease-in-out border-none bg-none text-sky-50 hover:text-red-600" onClick={handleClose}>
              <i className="">×</i>
            </button>
          </div>
          <div className="max-h-[500px] overflow-auto w-full px-4 h-full rounded-b">
            {activeMessage ? activeMessage.posts.map((message) => {
                return (
                  <div key={message.createdDateTime} className="pb-2 my-3 messages">
                    <h2 className="mb-3 text-lg font-semibold md:text-2xl text-sky-700">
                      {activeMessage.status} - {formatDateTime(message.createdDateTime)}
                    </h2>
                    {newlineText(message.description.content)}

                    <div className="h-[1px] w-full bg-sky-700/[0.5] mt-3" />
                  </div>
                );
              })
             : 
              <ProgressIndicator />
            }

          </div>
        </div>
    </div>
  );
}



export const newlineText = (text: string) => 
  text.split("\n").map((str, index) => <p key={index}>{str}</p>) */

  
import {
  ProgressIndicator, Spinner,
} from "office-ui-fabric-react";
import { useQuery } from "react-query";
import { fetchIncidentMessages } from "../../../api/incidents.service";
import { IHistoryMessageBody, IIncidentItem } from "../../../models/models";
import { useEffect } from "react";
import { formatDateTime } from "../../../helpers/helpers";
import { useTeamsContext } from "../../../context/teamsContext";



interface ICardProps {
  handleClose: () => void
  activeMessage: IIncidentItem
  status: string
}
export const MessageModal = ({ handleClose, status,  activeMessage}: ICardProps) =>  {
  const {getTenantId} = useTeamsContext()
  const params = {
    TenantId: getTenantId && getTenantId() || "",
    messageId: activeMessage.id,
  }
    const {isLoading, data} = useQuery<IHistoryMessageBody[]>(['getMessages', params], () => fetchIncidentMessages(params));
    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      }
    }, [])
    console.log(data)
  if(isLoading) return <Spinner />
  return (
    <div className="bg-black/[0.7] fixed top-0 left-0 z-5 w-full h-full d-flex items-center justify-center">      
        <div className="max-w-screen-lg mx-2 bg-white rounded-lg shadow">
          <div className="relative w-full px-2 py-3 rounded-t bg-sky-700 ">
            <h1 className="px-4 m-0 text-lg md:text-2xl text-sky-50">{activeMessage.title}</h1>
            <button className="absolute top-0 right-0 px-2 text-3xl transition duration-100 ease-in-out border-none bg-none text-sky-50 hover:text-red-600" onClick={handleClose}>
              <i className="">×</i>
            </button>
          </div>
          <div className="max-h-[500px] overflow-auto w-full px-4 h-full rounded-b">
            {data ? data.map((message) => {
                return (
                  <div key={message.publishTime} className="pb-2 my-3 messages">
                    <h2 className="mb-3 text-lg font-semibold md:text-2xl text-sky-700">
                      {status} - {formatDateTime(message.publishTime)}
                    </h2>
                    {newlineText(message.messageText)}

                    <div className="h-[1px] w-full bg-sky-700/[0.5] mt-3" />
                  </div>
                );
              })
             : 
              <ProgressIndicator />
            }

          </div>
        </div>
    </div>
  );
}



export const newlineText = (text: string) => 
  text.split("\n").map((str, index) => <p key={index}>{str}</p>)