import { useAutoAnimate } from "@formkit/auto-animate/react";
import React, { useEffect, useState } from "react";

export const DemoBanner = ()=> {
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
  const [parent] = useAutoAnimate()
  useEffect(() => {
    setIsBannerVisible(true);
  }, []);
  return (
    <div ref={parent} className="w-full">
      {isBannerVisible && (
        <div className="flex items-center justify-center w-full py-3 bg-orange-500">
          <i className={"ms-Icon ms-Icon--AlertSolid"} aria-hidden="true"></i>
          <p className="m-0">
          This site represents a demo version. The illustrated data is just for
          demonstrational purposes. There is no connection to your or any other
          production tenant.
          </p>
          <i className={"ms-Icon ms-Icon--AlertSolid"} aria-hidden="true"></i>
        </div>
      )}
    </div>
  );
}
