// Hook to fetch data with react query microsoft services

import { IDropdownOption } from "@fluentui/react";
import { useQuery } from "react-query";
import { fetchMicrosoftServices } from "../api/message.service";
import { useTeamsContext } from "../context/teamsContext";

export const useMSServices = () => {
    const {getTenantId} = useTeamsContext()
    const tenantId = getTenantId && getTenantId() || ""
    const { data: services, isFetching: isFetchingServices } = useQuery<IDropdownOption[]>(["microsoftServices", tenantId], () =>
        fetchMicrosoftServices(tenantId)
    );
    return { services, isFetchingServices };
    }