import {  ISelectedItem } from "../utils/interfaces";
import "./PopUp.css";
import {  ProgressIndicator } from "@fluentui/react";
import { fetchMessageModalData } from "../../../api/message.service";
import { useQuery } from "react-query";
import { IMessageDetailRequest } from "../../../api/models";
import { IMessage } from "../../../models/models";
import HTMLReactParser from "html-react-parser";
import { ServiceIconComponent } from "../../../commonComponents/ServiceIcon";
import { useTeamsContext } from "../../../context/teamsContext";



interface MessageModalProps {
  selectedItem: IMessage;
  handleClose: () => void;
}

export const MessageModal = ({
  selectedItem,
  handleClose
}: MessageModalProps) => {
  const {getTenantId} = useTeamsContext()
  const params: IMessageDetailRequest = {
    messageId: selectedItem.id,
    TenantId: (getTenantId && getTenantId()) || "",
  }
    const {data, isLoading} = useQuery<ISelectedItem>(["messageModal", params], () => fetchMessageModalData(params))


  return (
    <div className="bg-black/[0.7] fixed top-0 left-0 z-5 w-full h-full d-flex items-center justify-center">      
        <div className="w-full max-w-screen-lg mx-2 bg-white rounded-lg shadow ">
          <div className="relative w-full p-3 rounded-t bg-sky-700 ">
            <h1 className="m-0 md:text-xl text-sky-50">{selectedItem?.messageTitle}</h1>
            <button className="absolute top-0 right-0 px-2 text-3xl transition duration-100 ease-in-out border-none bg-none text-sky-50 hover:text-red-600" onClick={handleClose}>
              <i className="">×</i>
            </button>
          </div>
          <div className="flex flex-wrap justify-between px-4 pt-2">
            <div className="flex w-full md:w-[49%] flex-col">
              <BadgeComponent title="Start Time" content={selectedItem.startTime} />
              <BadgeComponent title="End Time" content={selectedItem.endTime} />
              <BadgeComponent title="Severity" content={selectedItem.severity} />              
            </div>
            <div className="w-full md:w-[49%]">
              <BadgeComponent title="Message ID" content={selectedItem.id} />
              <BadgeComponent title="Category" content={selectedItem.category} />
              <p className="m-0 text-sky-900">Services</p>
              <div className="flex flex-col">
               { selectedItem.service.map((serviceName: string) => <ServiceIconComponent key={serviceName} serviceName={serviceName} />)}
              </div>
            </div>
          </div>
          <div className="max-h-[500px] overflow-auto w-full h-full rounded-b pb-4 px-4">
            {isLoading ? <ProgressIndicator /> : null}
            {data ? <>
              <div className="bg-sky-700 h-[1px] w-full my-4"/>
              <div                
                className="popup-content"
              >{HTMLReactParser(data.messageText)}</div>
            </>
             : null
            }

          </div>
        </div>
    </div>
    
  );
}
const BadgeComponent = ({title, content, classes}:{title: string, content: string, classes?: string}) => {
  return (
    <div className={classes || ""}>
      <p className="m-0 text-sky-900">{title}</p>
      <div className="font-semibold text-sky-700">{content}</div>
    </div>
  )
}

